import React, { MutableRefObject, useEffect, useState } from 'react';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import DimensionSplitModal from '../modals/dimensionSplitModal/DimensionSplitModal';
import { ReactComponent as SplitIcon } from 'assets/icons/arrow-split.svg';

import styles from './Split.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { BudgetItem } from 'types/budget.types';
import { IServerSideGroupSelectionState } from 'ag-grid-community';
import { SplitCloseMessages, SplitMessages } from '../modals/dimensionSplitModal/splits.constants';
import { TransactionLineRequestParams } from '../../../services/statutory.service';

interface Props {
  gridRef: MutableRefObject<AgGridReact>;
  selectionChanged: MutableRefObject<() => void>;
  onSplitsUpdated: (data?: { updated: BudgetItem[] }) => void;
  currentTransactionRequestParams: TransactionLineRequestParams[];
  totalItemCount: number;
}

const Split = (
  {
    gridRef,
    selectionChanged,
    onSplitsUpdated,
    currentTransactionRequestParams,
    totalItemCount
  }: Props) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ splitItems, setSplitItems ] = useState<(TransactionLineRequestParams | BudgetItem)[]>([]);

  // We will set that reacting to the selection change. Depending on server side vs client side
  //  model, information will be from different place - either provided count or displayed rows
  const [ totalItems, setTotalItems ] = useState(totalItemCount);

  const [ selectedItemsCount, setSelectedItemsCount ] = useState(totalItemCount);

  const onClose = (message?: SplitCloseMessages, data?: { updated: BudgetItem[] }) => {
    setIsOpen(false);
    if (message || data?.updated) {
      onSplitsUpdated(data);
    }
  };

  const onMessage = (message: SplitMessages, data?: { updated: BudgetItem[] }) => {
    if (message === 'split:dimensionRemoved') {
      onSplitsUpdated(data);
    }
  };

  useEffect(() => {
    selectionChanged.current = (areAllLoaded?: boolean) => {
      if (gridRef.current.props.rowModelType === 'serverSide') {
        const serverSideSelected = gridRef.current.api.getServerSideSelectionState();
        setTotalItems(totalItemCount);

        if (serverSideSelected) {
          // With grouped nodes, toggled nodes contain also group nodes which are uuids,
          // so we remove them
          const toggledNodes = (serverSideSelected.toggledNodes as string[]).filter(
            (toggledNode: string) => !toggledNode.includes('-')
          );
          const selectedNodes = [];
          if (isServerSideGroupSelectionState(serverSideSelected)) {
            // todo: handle
          } else {
            if (serverSideSelected.selectAll) {
              if (!toggledNodes) {
                setSplitItems(currentTransactionRequestParams);
                setSelectedItemsCount(totalItemCount);
                return;
              } else {
                setSelectedItemsCount(totalItemCount - toggledNodes.length);
                setSplitItems(
                  currentTransactionRequestParams.map(
                    params => (
                      {
                        ...params,
                        // eslint-disable-next-line camelcase
                        excluded_transaction_line_ids: toggledNodes
                      }
                    )
                  )
                );
                return;
              }
            }
          }

          gridRef.current.api.forEachNode(node => {
            if (isServerSideGroupSelectionState(serverSideSelected)) {
              const isInList = serverSideSelected.toggledNodes
                .find(toggledNode => toggledNode.nodeId === node.id);

              if (serverSideSelected.selectAllChildren) {
                if (areAllLoaded) {
                  if (!isInList) selectedNodes.push(node.data);
                }
              } else {
                if (isInList) selectedNodes.push(node.data);
              }
            } else {
              const isInList = serverSideSelected.toggledNodes.includes(node.id);
              if (serverSideSelected.selectAll) {
                if (areAllLoaded) {
                  if (!isInList) selectedNodes.push(node.data);
                } else {
                  setSplitItems(currentTransactionRequestParams);
                }
              } else {
                if (isInList) selectedNodes.push(node.data);
              }
            }
          });
          setSplitItems(selectedNodes.flat());
        }

      } else {
        setSplitItems(gridRef.current.api.getSelectedRows());
        setTotalItems(gridRef.current.api.getDisplayedRowCount());
      }
    };
  }, [ totalItemCount ]);

  return (
    <>
      <GhostIconButton
        disabled={ !splitItems || splitItems?.length === 0 }
        onClick={ () => {
          setIsOpen(true);
        } }
        className={ styles.button }>
        <SplitIcon />
      </GhostIconButton>
      { splitItems?.length > 0 && <DimensionSplitModal
        splitItems={ splitItems?.filter(Boolean) }
        isOpen={ isOpen }
        onMessage={ onMessage }
        onClose={ onClose }
        overrideItemCount={ selectedItemsCount }
        totalItemCount={ totalItems }
      /> }
      
    </>
  );
};

export default Split;

function isServerSideGroupSelectionState(arg: unknown): arg is IServerSideGroupSelectionState {
  return (arg as IServerSideGroupSelectionState).selectAllChildren !== undefined;
}
