export type Person = {
  id: number;
  firstName: string;
  lastName: string;
  avatar?: string;
};

export type Organization = {
  id: number;
  name: string;
  chatbotConsent: boolean;
};

export enum FeatureFlag {
  DISABLE_REPORT_CACHE = 'DISABLE_REPORT_CACHE',
  HIDE_ORGANIZATION_DROPDOWN = 'HIDE_ORGANIZATION_DROPDOWN',
  REPORT_MODES = 'REPORT_MODES',
}

export type User = {
  pk: number;
  email: string;
  organizations: Organization[];
  isSuperuser: boolean;
  person?: Person;
  featureFlags: FeatureFlag[];
};

export type TokenRefreshRequest = {
  refresh: string;
};

export type TokenRefreshResponse = {
  access: string;
  refresh: string;
  accessTokenExpiration: Date;
};

export type LoginCredentials = {
  email: string;
  password: string;
};

export type ForgotPasswordRequest = {
  email: string;
};

export type ChangePasswordRequest = {
  newPassword1: string;
  newPassword2: string;
  uid: string;
  token: string;
};

export type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  user: User;
};

export type UserStore = {
  user: User | null;
  organization: Organization | null;
};
