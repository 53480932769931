import { InternalBudgetItem } from 'components/budget/budgetItemTable/hooks/useBudgetItems';
import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { DisplayName, PanelType } from './app.types';
import { Cadence } from './form.types';
import { ColDef } from 'ag-grid-community';
import {
  BreakdownType,
  ReportDataValues,
  ReportType,
  StatutoryRowCategory,
  UUID,
} from './templates.types';
import { TransactionLineRequestParams } from '../services/statutory.service';
import { BudgetItemType } from './budget.types';
import { FilterList } from './filterTable.types';

export type FrequencyUnit = 'one_time' | 'week' | 'month' | 'quarter';

// t('financials:templates.sections.all')
// t('financials:templates.sections.profit-and-loss')
// t('financials:templates.sections.balance-sheet')
// t('financials:templates.sections.cash-flow')
// t('financials:templates.sections.formulas')
// t('financials:templates.sections.dimensions')
// t('financials:templates.sections.counterparty')
// t('financials:templates.sections.metrics')
// t('financials:templates.sections.layout')

export type GridColDef = {
  field?: string;
  headerName?: string;
  aggFunc?: string;
  children?: GridColDef[];
};

export type GridColumns = {
  columnDefs: GridColDef[];
  columnFields: string[];
};

export enum RowType {
  FINANCIALS = 'financials',
  TITLE = 'title',
  GROUP = 'group',
  SUM_UP = 'sum_up',
  TRANSACTION = 'transaction',
  BREAKDOWN = 'breakdown',
  COUNTERPARTY_TYPE = 'counterparty_type',
  BREAKDOWN_GROUP = 'breakdown_group',
  TOTAL = 'total',
  SUBTOTAL = 'subtotal',
  SPACER = 'spacer',
  HALF_SPACER = 'half_spacer',
  DIMENSION_ITEM = 'dimension_item',
  FORMULA = 'new_formula',
  FINANCIAL_TITLE = 'financial_title',
  NEW_BUDGET_ITEM = 'new_budget_item',
  UNASSIGNED = 'unassigned_dimension',
  PRODUCT_PROXY = 'product_proxy',
}

export type FinancialNodeTransactions = { [ date: string ]: string };

export type FinancialsRowData = {
  id: number;
  accountRange: string;
  isLeaf: boolean;
  nodesPath: string[];
  name: DisplayName;
  shortName: DisplayName;
  tooltip: DisplayName;
  bold: boolean;
  highRow: boolean;
  numbersBold: boolean;
  accentedLine: boolean;
  capitalized: boolean;
  negativeMultiply: boolean;
  category: StatutoryRowCategory;
};

export type RowData = any; // eslint-disable-line

export type FinancialNode = {
  id: number;
  accountId?: number;
  children: number[];
  rowData: RowData;
  type: RowType;
  actual: FinancialNodeTransactions;
  plan: FinancialNodeTransactions;
  breakdownType: BreakdownType;
  isLeaf: boolean;
  hasTransactions: boolean;
};

export type FinancialRow = {
  id?: string;
  summary?: number;
  accountId?: string;
  filePath: string[];
  rowData: RowData;
  type: RowType;
  // True if row was generate only on client side (like dimension items from breakdowns)
  localRow: boolean;
  hasData: boolean;
  hasTransactions: boolean;
  isFailed: boolean;
  actual?: ReportDataValues;
  plan?: ReportDataValues;
  uuid: UUID;
  asyncStatus: 'not_loaded' | 'loading' | 'loaded' | null;
};

type HeaderRenderer = {
  headerGroupComponentParams: {
    cadence: string;
  };
  headerGroupComponent: ReactNode;
} | {
  headerComponentParams: {
    cadence: string;
  };
  headerComponent: ReactNode;
};

export type ColumnProps = {
  field?: string;
  isFirst?: boolean;
  year?: string;
  rowGroup?: boolean;
  groupId?: string;
  headerName: string;
  reportType: ReportType;
  type: string;
  filter?: string;
  children?: [];
};

export type TableColumns = {
  [ key: string ]: ColumnProps | HeaderRenderer | ColDef;
};

export type ColumnHeaderOptions = {
  cadence: Cadence;
  groupId?: (date: Dayjs, reportType?: ReportType) => string;
  name: (date: Dayjs, reportType?: ReportType) => string;
  nextCadence?: Cadence;
};

export type TableState = {
  [key in ActionButtonOptions]: boolean;
};

export type Period = {
  startDate?: number;
  endDate?: number;
  actualsOpen?: boolean;
  cadence?: Cadence;
  startDatePlan?: number;
  endDatePlan?: number;
  planOpen?: boolean;
  isManuallySet: boolean;
  fullRange?: boolean;
};

export type PeriodSectionsMode = 'single' | 'multiple';

export enum RangeType {
  DEFAULT = 'default',
  CUSTOM = 'custom'
}

export type PeriodSlice = {
  templateId: number;
  period: Period;
};

export enum LoadingStatus {
  TO_LOAD = 'to_load',
  LOADING = 'loading',
  LOADED = 'loaded',
}

// t('dynamicColumns:columnType.totals')
// t('dynamicColumns:columnType.change')
// t('dynamicColumns:columnType.percentageOfRevenue')
// t('dynamicColumns:columnType.budgetVariance')
export enum ColumnType {
  TOTALS = 'totals',
  CHANGE = 'change',
  PERCENTAGE_OF_REVENUE = 'percentageOfRevenue',
  BUDGET_VARIANCE = 'budgetVariance',
}

export interface DynamicSettingsConfiguration {
  id: number;
  title: string;
  columnType: ColumnType;
  value: DynamicColumnType;
  subtitle: string;
  active: boolean;
  optionsList?: string[];
  options: string[];
  disabledCadences: Cadence[];
}

type DynamicSettingsValues = {
  columns: DynamicSettingsConfiguration[];
  conditionalFormatting: boolean;
};

export type DynamicSettings = {
  [key in Cadence | 'all']: DynamicSettingsValues;
};

export interface DynamicColumnSettings {
  id: number;
  name: string;
  value: string;
}

export enum ActionButtonOptions {
  ACCOUNTS_NUMBER = 'accountsNumber',
  COUNTERPARTY_LOGOS = 'counterpartyLogos',
  DIMENSION_LABELS = 'dimensionLabels',
  DIMENSION_NUMBERS = 'dimensionNumbers',
  ACCOUNTS = 'accounts',
  DYNAMIC_COLUMNS = 'dynamicColumns',
  PIN_TOTALS = 'pinTotals',
}

// Sorted by placement in the table
export enum DynamicColumnType {
  PERCENTAGE_OF_REVENUE_BASE = 'REVENUE%_BASE',
  PERCENTAGE_OF_REVENUE_TOTALS = 'REVENUE%_TOTALS',

  PERIOD_NOMINAL = 'POP',
  PERIOD_PERCENT = 'POP%',

  YEAR_NOMINAL = 'YOY',
  YEAR_PERCENT = 'YOY%',

  BUDGET_VS_ACTUAL = 'BVA',
  BUDGET_VS_ACTUAL_PERCENT = 'BVA%',
  BUDGET_VS_ACTUAL_TOTALS = 'BVA_TOTALS',

  QUARTERLY_TOTAL = 'Q',
  HALF_YEAR = 'H',
  YEAR_TO_DATE = 'YTD',
  CALENDAR_YEAR = 'CY',
  FISCAL_YEAR = 'FY',
  TRAILING_3_MONTHS = 'T3M',
  TRAILING_6_MONTHS = 'T6M',
  TRAILING_12_MONTHS = 'TTM',
  BUDGET_TOTAL = 'BUDGET_TOTAL',
  ACTUAL_TOTAL = 'ACTUAL_TOTAL',
  GRAND_TOTAL = 'GRAND'
}

export interface TotalColumnsSettings {
  type: DynamicColumnType;
  options: string[];
}

export type ColumnsSettings = {
  columns: TotalColumnsSettings[];
  conditionalFormatting: boolean;
};

export type CadenceColumnsSettings = {
  [key in Cadence]: ColumnsSettings;
};

export enum DriversCategory {
  ALL = 'all',
  GENERAL = 'general',
}

export type TableTitle = {
  title: string;
  subtitle: string;
};

export enum ReportSource {
  PROCOUNTOR = 'procountor',
  SYSTEM = 'system'
}

export type ActiveCardType = 'table' | 'inputs' | 'chart' | 'big_number';

export interface FinancialPanel {
  type: PanelType | null;
  table: number | null;
}

export type ViewItemType = 'table' | 'inputs';

interface ViewItemBase {
  type: ActiveCardType;
  isShown: boolean;
}

interface TemplateItem extends ViewItemBase {
  templateId: number;
}

interface InputsItem extends ViewItemBase {
  templateId: null;
}

export type ViewItem = TemplateItem | InputsItem;

export type ITemplateSettingSlice<T> = {
  templateId: number;
  value: T;
};

export type LazyLoadingRowExpansionState = {
  nodeId: string;
  expandedChildren: string[];
};

export type DataType = 'Plan' | 'Actuals';

export type ParamsByReportType = {
  [key in ReportType]: TransactionLineRequestParams[];
};
export interface DetailedViewSettings {
  templateId: number;
  period: Period;
  params: ParamsByReportType;
  filter: FilterList;
  budgetItemType: BudgetItemType;
  type: DetailedViewType;
  sectionKey: string;
  data: {
    budgetItems: InternalBudgetItem[];
  };
}

export enum DetailedViewType {
  MODAL = 'modal',
  FLOATING_PANEL = 'floating_panel'
}

export type NumberSign = 'positive' | 'negative' | 'neutral';
