import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetItem } from 'types/budget.types';
import { Transaction } from 'types/statutory.types';
import { numberFormatter } from 'utils/common.utils';
import { getLabel, isTransaction } from '../split.utils';
import styles from './SplitItemLabel.module.scss';
import {
  isTransactionLineRequestParams,
  TransactionLineRequestParams
} from '../../../../../services/statutory.service';

const SplitItemLabel = (
  { splitItem }: { splitItem: Transaction | BudgetItem | TransactionLineRequestParams }) => {
  const [ t ] = useTranslation('financials');
  // const amount = isTransaction(splitItem) ? splitItem.amount : splitItem.amountFormula;

  const amount = useMemo(() => {
    if (isTransactionLineRequestParams(splitItem)) {
      return 0;
    } else {
      if (isTransaction(splitItem)) {
        return splitItem.amount;
      } else {
        return splitItem.amountFormula;
      }
    }
  }, [ splitItem ]);

  const prefix = useMemo(() => {
    if (isTransactionLineRequestParams(splitItem)) {
      return '';
    } else {
      return isTransaction(splitItem) && !splitItem.budgetItem ?
        t('actual-label') :
        t('budget-label');
    }
  }, [ splitItem ]);

  return <div className={ styles.tlsOption }>
    <div className={ styles.splitItemTypeBadge }>
      { prefix }
    </div>
    <span className={ styles.description }>
      { getLabel(splitItem) }
    </span>
    <span className={ styles.sum }>
      {
        isNaN(Number(amount)) ? amount : numberFormatter(Number(amount), true) }
    </span>
  </div>;
};

export default SplitItemLabel;
