import { TableColDef } from 'components/elements/tableWrapper/types/table.types';

export const isColumnHidable = (column: TableColDef) => {
  return Boolean(!column.suppressHide && column.headerName);
};

export function getColumnWidthLSKey (column: string) {
  return `column__width:${ column }`;
}

export function getColumnWidthLS(columnId: string) {
  const columnWidth = localStorage.getItem(getColumnWidthLSKey(columnId));
  return columnWidth ? parseInt(columnWidth) : null;
}
