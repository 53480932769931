import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert } from 'antd';

import { notifySuccess } from 'utils/notifications.utils';
import { ErrorMessage } from 'types/form.types';
import { getValidationRules } from 'utils/form.utils';
import { routes } from 'utils/config.utils';
import { changePassword } from 'services/auth.service';

import Input from 'components/elements/input/Input';
import PasswordStrengthBar from 'components/elements/passwordStrengthBar/PasswordStrengthBar';
import Button from 'components/elements/button/Button';
import AuthCard from 'components/auth/authCard/AuthCard';
import Loader from 'components/elements/loader/Loader';

import styles from './ChangePassword.module.scss';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [ query ] = useSearchParams();
  const [ t ] = useTranslation('auth');

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    register,
  } = useForm({ mode: 'all' });
  const { isValid } = useFormState({ control });
  const [ errorMessage, setErrorMessage ] = useState<ErrorMessage>();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isStrongPassword, setIsStrongPassword ] = useState<boolean>(false);

  const password = watch('newPassword1');

  register('newPassword1', {
    onBlur: () => trigger('newPassword2')
  });

  const inputs = [
    {
      name: 'newPassword1',
      label: t('input-labels.new-password'),
      type: 'password',
      rules: getValidationRules(
        { required: true, maxLength: 128 },
        'Password'
      )
    },
    {
      name: 'newPassword2',
      label: t('input-labels.confirm-password'),
      type: 'password',
      rules: {
        ...getValidationRules(
          { required: true, maxLength: 128 },
          'Password'
        ),
        validate: {
          same: (value) => value == password,
        }
      }
    }
  ];

  const onSubmit = (values) => {
    if (isStrongPassword) {
      setIsLoading(true);
      changePassword({
        ...values,
        uid: query.get('uid'),
        token: query.get('token')
      })
        .then(() => {
          notifySuccess(
            t('notifications.change-password.message'),
            t('notifications.change-password.description'),
          );
          navigate(routes.login);
        })
        .catch(({ response }) => {
          if (response.status === 404)
            setErrorMessage({
              message: t('notifications.user-not-found.message', { ns: 'common' }),
              type: 'error'
            });
          else
            setErrorMessage({
              message: t('notifications.unexpected-error.message', { ns: 'common' }),
              type: 'error'
            });
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    const error = Object.values(errors)?.[ 0 ];
    if (error && error.type !== 'manual') {
      if (error.type === 'same') {
        setErrorMessage({
          message: t('notifications.confirm-password.message'),
          description: t('notifications.confirm-password.description'),
          type: 'error'
        });
      } else
        setErrorMessage({ message: error.message as string, type: 'error' });
    } else {
      errorMessage && setErrorMessage(null);
    }
  }, [ errors.password, errors.confirmPassword ]);

  return (
    <AuthCard containerClassName={ styles.container }>
      <h1 className={ styles.header }>
        { t('page.change-password.title') }
      </h1>
      <p className={ styles.text }>
        { t('page.change-password.description') }
      </p>
      <form
        onSubmit={ handleSubmit(onSubmit) }
        className={ styles.form }
      >
        { errorMessage && (
          <Alert
            message={ errorMessage.message }
            description={ errorMessage.description }
            type={ errorMessage.type }
            showIcon
            className={ styles.alert }
          />
        ) }
        { inputs.map(input => (
          <Input
            key={ input.name }
            control={ control }
            errors={ errors }
            className={ styles.input }
            { ...input }
          />
        )) }
        { password && (
          <PasswordStrengthBar password={ password } onChange={
            (isStrong: boolean) => setIsStrongPassword(isStrong)
          }/>
        ) }
        <Button
          name={ t('buttons.change-password') }
          className={ styles.button }
          htmlType='submit'
          disabled={ !isValid || !isStrongPassword }
        />
        <Link to={ routes.login } className={ styles.link }>
          { t('buttons.login') }
        </Link>
      </form>
      <Loader isActive={ isLoading }/>
    </AuthCard>
  );
};

export default ChangePassword;
