import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { TableColDef } from '../../elements/tableWrapper/types/table.types';
import { AgGridReact } from 'ag-grid-react';
import { Collapse, Switch } from 'antd';
import styles from './ColumnSelection.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/button/Button';
import { groupBy } from 'lodash';
import { isColumnHidable } from 'components/elements/tableWrapper/utils/columns.utils';
import useColumnStorage from 'components/elements/tableWrapper/hooks/useColumnStorage';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import clsx from 'clsx';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import { useSearchable } from 'context/SearchableContext';
import { Column } from 'ag-grid-community';
import HighlightedText from 'components/elements/highlightedText/HighlightedText';
import { useLocalStorage } from 'hooks/useLocalStorage';
import FavouriteStar from 'components/elements/favouriteStar/FavouriteStar';

interface Props {
  sectionKey?: string;
  isGridReady: boolean;
  gridRef: React.MutableRefObject<AgGridReact>;
  tableKey: string;
  afterApply?: () => void;
}

const ColumnSelectionDropdown = ({
  gridRef,
  isGridReady,
  tableKey,
  sectionKey,
  afterApply,
}: Props) => {
  const [ t ] = useTranslation('common');
  const { save, deleteStored } = useColumnStorage({ key: tableKey + (sectionKey || '') });
  const [ activeKeys, setActiveKeys ] = useState<string[]>([]);
  const [ favouriteColumns, setFavouriteColumns ] = useLocalStorage<string[]>(
    `favouriteColumns-${ tableKey }`, []
  );

  const onFavourite = (field: string) => {
    if (favouriteColumns.includes(field)) {
      setFavouriteColumns(favouriteColumns.filter((col) => col !== field));
    } else {
      setFavouriteColumns([ ...favouriteColumns, field ]);
    }
  };

  const { state: { search } } = useSearchable();

  const hidableColumns = useMemo(() => {
    return gridRef.current?.columnApi?.getAllGridColumns?.()?.filter((column) => {
      const colDef: TableColDef = column.getColDef();
      return isColumnHidable(colDef);
    });
  }, [ gridRef.current?.columnApi?.getAllGridColumns() ]);

  const [ visibleColumns, setVisibleColumns ] = useState<string[]>([]);

  const updateVisibleColumns = useCallback(() => {
    // setTimeout is to wait for every column to be properly set as visible by ag-grid
    setTimeout(() => {
      if (gridRef.current == null) return;
      const _visibleColumns = gridRef.current.columnApi?.getAllGridColumns?.()
        ?.filter(col => col.isVisible() && isColumnHidable(col.getColDef()))
        .map(col => col.getColDef().field);

      setVisibleColumns(_visibleColumns);
    });
  }, [ tableKey, sectionKey ]);

  useEffect(() => {
    if (tableKey && isGridReady) {
      updateVisibleColumns();
    }
  }, [ tableKey, isGridReady, sectionKey ]);

  const groupedColumns = useMemo(() => {
    const grouped = groupBy(hidableColumns, 'userProvidedColDef.group');

    return {
      [ t('left-panel.favourites') ]: hidableColumns?.filter(
        (column) => favouriteColumns.includes(column.getColDef().field)
      ),
      ...grouped
    };
  }, [ tableKey, hidableColumns, favouriteColumns ]);

  useEffect(() => {
    setActiveKeys(
      Object.keys(groupedColumns)
        .map((key) => key === 'undefined'? t('left-panel.columns.other') : key)
    );
  }, [ search, groupedColumns, setActiveKeys ]);

  const onApply = useCallback(() => {
    const hiddenColumns = [];
    const shownColumns = [];

    hidableColumns?.forEach((column) => {
      const colDef: TableColDef = column.getColDef();
      const isHidden = !visibleColumns.some((visibleColumn) => visibleColumn === colDef.field);
      (isHidden ? hiddenColumns : shownColumns).push(colDef.field);
    }, []);

    if (!hiddenColumns) return;

    gridRef.current.columnApi.setColumnsVisible(hiddenColumns, false);
    gridRef.current.columnApi.setColumnsVisible(shownColumns, true);
    save(gridRef.current.columnApi);

    afterApply?.();
  }, [ hidableColumns, visibleColumns ]);

  const onRestoreDefault = useCallback(() => {
    deleteStored();
    gridRef.current.columnApi.resetColumnState();
    updateVisibleColumns();
  }, []);

  const onToggleHide = useCallback((field: string) => {
    setVisibleColumns((prevColumns) => {
      const index = prevColumns.findIndex((column) => column === field);
      if (index > -1) {
        return prevColumns.filter((colField) => colField !== field);
      }
      return [ ...prevColumns, field ];
    });
  }, [ tableKey ]);

  const isAllInGroupShown = (group: string) => {
    const groupColumns = groupedColumns[ group ];
    if (!groupColumns) return false;
    return groupColumns
      .every((column) => visibleColumns.includes(column.getColDef().field));
  };

  const onToggleAllGroupColumns = (event: MouseEvent, group: string) => {
    event.stopPropagation();

    if (isAllInGroupShown(group)) {
      setVisibleColumns(visibleColumns.filter((column) => {
        return !groupedColumns[ group ]
          .some((col) => col.getColDef().field === column);
      }));
    } else {
      setVisibleColumns([ ...visibleColumns, ...groupedColumns[ group ]
        .map((col) => col.getColDef().field) ]);
    }
  };

  const onCollapseChange = (key: string | string[]) => {
    // In state accordion === false key is always string[]
    if (Array.isArray(key)) {
      setActiveKeys(key);
    } else {
      setActiveKeys([ key ]);
    }
  };

  return <div className={ styles.container }>
    <DynamicScrollableContainer className={ styles.scrollableContainer }>
      <Collapse
        ghost={ true }
        activeKey={ activeKeys }
        className={ clsx(styles.collapseContainer) }
        expandIcon={ (props) => <Chevron
          className={ clsx(styles.expandIcon, { [ styles.isExpand ]: props.isActive }) }
        /> }
        onChange={ onCollapseChange }
      >
        { Object.keys(groupedColumns).map((groupName) => {

          const columns = filterColumns(search, groupedColumns[ groupName ]);

          if (columns.length === 0) return null;

          return (
            <Collapse.Panel
              header={ <div className={ styles.collapseHeader }>
                <span className={ styles.groupHeader }>
                  { groupName !== 'undefined' ? groupName : t('left-panel.columns.other') }
                  { groupName === t('left-panel.favourites') && <FavouriteStar isFavourite /> }
                </span>
                <Button type='link' className={ styles.toggleSelectAllButton }
                  onClick={ (event) => onToggleAllGroupColumns(event, groupName) }>
                  { isAllInGroupShown(groupName) ?
                    t('left-panel.columns.unselect-all') : t('left-panel.columns.select-all')
                  }
                </Button>
              </div> }
              key={ groupName === 'undefined' ? t('left-panel.columns.other') : groupName }
              className={ styles.collapsePanel }
            >
              <div className={ styles.group } key={ groupName }>
                <div className={ styles.columns }>
                  {
                    columns.map((column) => {
                      const isVisible = visibleColumns.some(col => col === column.field);
                      return (
                        <label
                          className={ clsx(styles.label, 'starContainer') }
                          key={ column.field }
                        >
                          <Switch
                            onChange={ () => onToggleHide(column.field) }
                            checked={ isVisible }
                          />

                          <span className={ styles.columnName }>
                            <HighlightedText
                              text={ column.headerName }
                              highlight={ search }
                            />
                            <FavouriteStar
                              isFavourite={ favouriteColumns.includes(column.field) }
                              onClick={ () => onFavourite(column.field) }
                              hideStar={ groupName !== t('left-panel.favourites') }
                            />
                          </span>
                        </label>
                      );
                    })
                  }
                </div>
              </div>
            </Collapse.Panel>
          );
        }) }

      </Collapse>

    </DynamicScrollableContainer>
    <div className={ styles.buttons }>
      <Button
        className={ styles.restoreDefaultButton }
        type='link'
        onClick={ onRestoreDefault }
        aria-label='restore-default'>
        { t('left-panel.columns.restore-default') }
      </Button>
      <Button onClick={ onApply } aria-label='apply'>
        { t('left-panel.columns.apply') }
      </Button>
    </div>
  </div>;
};

export default ColumnSelectionDropdown;

function filterColumns(search: string, columns: Column[] | null | undefined) {
  if (!columns) return [];

  const cols = columns.map((col) => col.getColDef());

  if (search === '') return cols;
  return cols.filter((column) => column.headerName.toLowerCase().includes(search.toLowerCase()));
}
