import React from 'react';
import { IRowNode } from 'ag-grid-community';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { BudgetItemType, BudgetItem } from 'types/budget.types';
import { DimensionItem, Dimension } from 'types/filterTable.types';
import { FinancialNode } from 'types/financials.types';

import { ReactComponent as StartPointIcon } from 'assets/icons/start-point.svg';
import { ReactComponent as EndPointIcon } from 'assets/icons/end-point.svg';
import { ReactComponent as RecurringIcon } from 'assets/icons/recurring.svg';
import { ReactComponent as ClockOnwardIcon } from 'assets/icons/clock-onward.svg';
import { getDisplayName } from '../../../../utils/common.utils';

export interface Entry {
  startDate: string;
  key: string;
  [key: string]: unknown;
}

export const COLUMN_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const fieldToIconMap = {
  'startDate': <StartPointIcon />,
  'endDate': <EndPointIcon />,
  'duration': <ClockOnwardIcon />,
  'frequency': <RecurringIcon />
};

export const defaultFields = [
  'budgetItemType',
  'counterparty',
  'memo',
  'amountFormula',
  'startDate',
  'duration',
  'endDate',
  'frequency'
];

export function getEntryLabel(entry: Entry) {
  let label = '';

  const memo = entry.memo as string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const type = (entry.budgetItemType as any)?.name?.[ 'en' ];
  const date = entry.startDate ? dayjs(entry.startDate).format('DD.MM.YYYY') : entry.key;
  if (memo) label += limitStringLength(memo);
  if (type) label += ` ${ limitStringLength(type) }`;
  if (date) label += ` ${ date }`;

  return label;
}

function limitStringLength(str?: string, limit = 10) {
  return str?.length > limit ? `${ str?.slice(0, limit) }...` : str;
}

export function getDefaultBudgetItem (budgetItemType: BudgetItemType) {
  const itemTypeRelatedSettings: Partial<BudgetItem> = budgetItemType != null ? {
    memo: getDisplayName(budgetItemType.memo),
    accounts: budgetItemType.defaultAccounts,
  }: {};

  return {
    ...itemTypeRelatedSettings,
    itemType: budgetItemType?.id,
    dimensionItems: [],
    dimensionItemIds: [],
  };
}

export function getDefaults (
  sourceNode: IRowNode<FinancialNode>,
  sourceColumn: string,
  defaultBudgetItem: BudgetItemType
) {
  const budgetItemDefaults = getDefaultBudgetItem(defaultBudgetItem);

  const rowData = sourceNode?.data?.rowData;

  const dimension = ((rowData?.dimensionItems ?? []) as DimensionItem[])
    .reduce<Record<Dimension['id'], DimensionItem['id']>>((acc, DI) => {
    acc[ DI.dimensionId ] = DI.id;
    return acc;
  }, {});

  const dateColumn = sourceColumn.split('__').at(1);
  const dateFallback = dateColumn ? dayjs(dateColumn)
    .startOf('month').format('YYYY-MM-DD') : '';

  return {
    ...budgetItemDefaults,
    ...rowData,
    memo: rowData?.memo,
    budgetItemType: rowData?.budgetItemType,
    accounts: { primary: rowData?.account ?? rowData.accounts?.primary ??
        budgetItemDefaults?.accounts?.primary },
    amountFormula: rowData.entries?.[ dateColumn ]?.amountFormula ??
      Object.values(sourceNode.data.plan).at(0) ?? 0,
    startDate: rowData.entries?.[ dateColumn ]?.startDate ??
      Object.keys(sourceNode.data.plan).at(0) ??
      dateFallback,
    endDate: rowData?.endDate,
    frequency: {
      unit: rowData.frequencyUnit ?? 'one_time',
      amount: rowData.frequencyAmount ?? 1,
    },
    duration: rowData.duration ?? undefined,
    dimension,
    counterparty: rowData?.counterparty
  };
}

export function getDefaultsForMultiple(
  nodes: IRowNode<FinancialNode>[],
  defaultBudgetItem: BudgetItemType,
  placeholderMap?: Map<string, string>,
  columns?: string[]
) {
  const defaults = nodes.flatMap(node => {
    return (columns || Object.keys(node.data.rowData.entries)).map(column => {
      return getDefaults(node, column, defaultBudgetItem);
    });
  });

  const def = defaults.reduce((acc, item) => {
    if (acc == null) return { ...item };

    for (const key in item) {
      if (key !== 'dimensionItems' && Array.isArray(item[ key ])) continue;

      if (typeof item[ key ] === 'object') {

        if (key === 'dimensionItems') {
          acc[ key ].forEach((accDimensionItem) => {
            item[ key ].forEach((thisDimensionItem) => {
              if (accDimensionItem.dimensionId !== thisDimensionItem.dimensionId) {
                acc[ `dimension.${ thisDimensionItem.dimensionId }` ] = undefined;
                if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
              }
            });
          });
          continue;
        } else if (key === 'accounts') {
          if (acc[ key ]?.primary !== item[ key ]?.primary) {
            acc[ key ] = undefined;
            if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
          } else {
            continue;
          }
        } else {
          if (acc[ key ]?.id !== item[ key ]?.id) {
            acc[ key ] = undefined;
            if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
          } else {
            continue;
          }
        }

      }

      if (acc[ key ] !== item[ key ]) {
        acc[ key ] = undefined;
        if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
      }
    }

    return acc;

  }, null);

  for (const key in def) {
    if (def[ key ] === undefined && !placeholderMap.has(key)) {
      placeholderMap.set(key, t('common:none'));
    }
  }

  return def;
}

const notNull = (val: unknown) => val != null;

export function createUpdates (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any,
  nodesForUpdate: IRowNode[],
  dimensionsItemsMap: Record<number, DimensionItem>,
  dimensionsMap: Record<number, Dimension>,
  allColumns: string[],
  selectedEntry: string | null,
  startDate: string,
) {
  const dimensionItems = Object.values(formData.dimension)
    .filter(notNull)
    .map((id: number) => dimensionsItemsMap[ id ])
    .filter((dimensionItem) => {
      const dimensionName = dimensionsMap[ dimensionItem.dimensionId ]?.relation;
      if (dimensionName === 'COUNTERPARTY' || dimensionName === 'ACCOUNT') {
        return false;
      }
      return dimensionItem;
    });

  return nodesForUpdate.map((node) => {
    const entries = {
      ...(node.data.rowData.entries ?? {})
    };
    const plan = {
      ...(node.data.plan ?? {})
    };

    // Remove cells that was empty before
    for (const entry in entries) {
      if (!entries[ entry ].amountFormula) {
        delete entries[ entry ];
        delete plan[ entry ];
      }
    }

    if (selectedEntry == null) {
      allColumns
        .filter((column) => {
          // Filter out columns that are not in this node
          return node.data.rowData.entries?.[ column.split('__').at(1) ] != null;
        })
        .forEach((column) => {
          const [ , date ] = column.split('__');

          const value = plan[ date ];
          delete plan[ date ];
          delete entries[ date ];

          entries[ formData.startDate ? startDate : date ] = {
            ...node.data.rowData.entries[ date ],
            ...(formData.amountFormula ? { amountFormula: formData.amountFormula } : {}),
            ...(formData.startDate ? { startDate: formData.startDate } : {}),
          };
          plan[ formData.startDate ? startDate : date ] = formData.amountFormula ?
            formData.amountFormula : value;
        });

    } else {
      const [ date ] = selectedEntry.split('__');
      delete plan[ date ];
      delete entries[ date ];

      entries[ formData.startDate ? startDate : date ] = {
        ...node.data.rowData.entries[ formData.date ],
        ...(formData.amountFormula ? { amountFormula: formData.amountFormula } : {}),
        ...(formData.startDate ? { startDate: formData.startDate } : {}),
      };
      plan[ formData.startDate ? startDate : date ] = formData.amountFormula;
    }

    return {
      ...node.data,
      plan,
      rowData: {
        ...node.data.rowData,
        accounts: formData.budgetItemType.defaultAccounts.primary,
        ...formData,
        frequencyUnit: formData.frequency.unit,
        frequencyAmount: formData.frequency.amount,
        entries,
        dimensionItemIds: dimensionItems.map(item => item.id),
        dimensionItems,
      }
    };
  });
}

export function getEntries (nodes: IRowNode<FinancialNode>[], columns: string[]) {
  return nodes.flatMap((node) => Object.entries(node.data?.rowData?.entries ?? {})
    .filter(([ key, value ]: [ string, Entry ]) => {
      return columns.includes(`plan__${ key }`) && value.amountFormula;
    })
    .flatMap(([ key, value ]: [ string, object ]) => ({
      key: `${ key }__${ node.id }`,
      ...node.data.rowData,
      ...value,
    }))) as Entry[];
}

export function setPlaceholdersForDimensions(
  placeholderMap: Map<string, string>,
  nodes: IRowNode[], dimensionsFields: string[]
) {
  if (!placeholderMap) return;

  dimensionsFields.forEach((dimensionField) => {
    const dimensionId = dimensionField.split('.').at(1);
    const dimensionItems = nodes.flatMap(node => node.data.rowData.dimensionItems)
      .filter((item) => item.dimensionId === dimensionId);

    if (dimensionItems.length > 1) {
      placeholderMap.set(dimensionField, t('common:form.multiple'));
    } else if (dimensionItems.length === 0) {
      placeholderMap.set(dimensionField, t('common:none'));
    }
  });
}
