import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { SortIndex, SortType } from 'components/elements/tableWrapper/types/table.types';
import { Control, Controller, useForm } from 'react-hook-form';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';
import { ColumnState } from 'ag-grid-community';
import { ReactComponent as SortAscendingIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as SortDescendingIcon } from 'assets/icons/sort-descending.svg';
import styles from './SortSelection.module.scss';
import clsx from 'clsx';
import Button from 'components/elements/button/Button';

type ValueType = string | number;

interface Props {
  gridRef: React.MutableRefObject<AgGridReact>;
  isDisabled?: boolean;
  sortState?: Partial<FormType>;
  defaults?: Partial<FormType>;
  sortOptions?: { label: string; value: ValueType }[] | null;
  customSortHandler?: ((sortSetting: Partial<FormType>) => void) | null;
  onSortReset?: () => void;
}

export interface ColumnSortSettings {
  colId?: string | number;
  sort?: SortType;
  sortIndex?: SortIndex;
}

export type FormType = {
  [SortIndex.PRIMARY]: ColumnSortSettings;
  [SortIndex.SECONDARY]: ColumnSortSettings;
};

const SortSelectionContent = ({
  gridRef,
  sortOptions,
  sortState,
  defaults,
  isDisabled = false,
  customSortHandler,
  onSortReset
}: Props) => {
  const [ t ] = useTranslation('sorting');

  const emptySortSetting = {
    label: t('none-sorting'),
    value: ''
  };

  const getEmptySortSettings = (sortIndex: SortIndex): ColumnSortSettings => {
    return {
      colId: emptySortSetting.value,
      sort: 'desc',
      sortIndex
    };
  };

  const sortIndexToNumber = useCallback((sortIndex: SortIndex) => {
    return Object.values(SortIndex).indexOf(sortIndex);
  }, []);

  const getDefaultSortSettings = (sortIndex: SortIndex): ColumnSortSettings => {
    const state = gridRef.current?.columnApi?.getColumnState() ?? [];
    const setting = state.find((col) => col.sortIndex === sortIndexToNumber(sortIndex));
    if (setting) {
      return {
        colId: setting.colId,
        sort: setting.sort ?? 'desc',
        sortIndex
      };
    }
    return getEmptySortSettings(sortIndex);
  };

  const { control, reset, setValue, watch } = useForm<FormType>({
    defaultValues: {
      [ SortIndex.PRIMARY ]: getDefaultSortSettings(SortIndex.PRIMARY),
      [ SortIndex.SECONDARY ]: getDefaultSortSettings(SortIndex.SECONDARY),
    }
  });
  const values = watch();

  useEffect(() => {
    if (sortState) {
      const newValues = {
        [ SortIndex.PRIMARY ]: sortState[ SortIndex.PRIMARY ] ??
          getEmptySortSettings(SortIndex.PRIMARY),
        [ SortIndex.SECONDARY ]: sortState[ SortIndex.SECONDARY ] ??
          getEmptySortSettings(SortIndex.SECONDARY)
      };
      reset(newValues);
    }
  }, [ sortState, reset ]);

  const options = useMemo(() => {
    if (sortOptions && sortOptions.length > 0) {
      return sortOptions;
    }

    return gridRef?.current?.columnApi?.getColumns()
      ?.filter((col) => {
        const colDef = col.getColDef();
        return colDef.sortable && colDef.headerName;
      })
      .map((col) => ({
        label: col.getColDef().headerName,
        value: col.getColId()
      }));
  }, [ gridRef?.current?.columnApi, sortOptions ]);

  const sortSettingsToColumnState = (sortIndex: SortIndex, val: Partial<FormType>) => {
    return {
      colId: val[ sortIndex ]?.colId as string,
      sort: val[ sortIndex ]?.sort,
      sortIndex: sortIndexToNumber(sortIndex)
    };
  };

  const setSorting = (newValues: Partial<FormType>) => {
    const _values = newValues ?? values;

    const sortModel: ColumnState[] = [];
    if (_values[ SortIndex.PRIMARY ]?.colId) {
      sortModel.push(sortSettingsToColumnState(SortIndex.PRIMARY, _values));
    }
    if (_values[ SortIndex.SECONDARY ]?.colId) {
      sortModel.push(sortSettingsToColumnState(SortIndex.SECONDARY, _values));
    }
    gridRef.current.columnApi.applyColumnState({
      state: sortModel, defaultState: { sort: null, sortIndex: null }
    });
  };

  const applySorting = (newValues?: Partial<FormType>) => {
    if (customSortHandler) {
      customSortHandler(newValues ?? values);
    } else {
      setSorting(newValues);
    }
  };

  const restoreDefault = () => {
    const defaultValues = {
      [ SortIndex.PRIMARY ]: defaults[ SortIndex.PRIMARY ]
        ?? getEmptySortSettings(SortIndex.PRIMARY),
      [ SortIndex.SECONDARY ]: defaults[ SortIndex.SECONDARY ]
        ?? getEmptySortSettings(SortIndex.SECONDARY)
    };

    reset(defaultValues);
    if (onSortReset) {
      onSortReset();
    } else {
      applySorting(defaultValues);
    }
  };

  return <div className={ clsx(styles.container, { [ styles.disabled ]: isDisabled }) }>
    <p className={ styles.title }>
      { t('title') }
    </p>
    <SortRow
      sortIndex={ SortIndex.PRIMARY }
      setValue={ setValue }
      applySorting={ applySorting }
      title={ t('level.primary') }
      values={ values }
      options={ options }
      emptySortSetting={ emptySortSetting }
      control={ control }
    />
    <SortRow
      sortIndex={ SortIndex.SECONDARY }
      setValue={ setValue }
      title={ t('level.secondary') }
      values={ values }
      options={ options }
      emptySortSetting={ emptySortSetting }
      control={ control }
      applySorting={ applySorting }
    />

    <Button
      className={ styles.restoreButton }
      type='default'
      disabled={ isDisabled || !defaults }
      onClick={ restoreDefault }
    >
      { t('common:left-panel.columns.restore-default') }
    </Button>
  </div>;
};

export default SortSelectionContent;

interface SortRowProps {
  sortIndex: SortIndex;
  title: string;
  values: Partial<FormType>;
  options: { label: ReactNode; value: ValueType }[];
  emptySortSetting: { label: ReactNode; value: string };
  control: Control<FormType, object>;
  setValue: (name: string, value: string) => void;
  applySorting?: () => void;
}

const SortRow = ({
  values, sortIndex, title, options, emptySortSetting, control, applySorting, setValue
}: SortRowProps) => {
  const otherIndex = sortIndex === SortIndex.PRIMARY ? SortIndex.SECONDARY : SortIndex.PRIMARY;
  const otherCol = values[ otherIndex ]?.colId;
  const filteredOptions = [
    emptySortSetting,
    ...(options?.filter((option) => option.value !== otherCol) ?? [])
  ];

  return <>
    <p>{ title }</p>
    <div className={ styles.sortRow }>
      <Controller
        control={ control }
        name={ `${ sortIndex }.colId` }
        render={ ({ field: { onChange, value } }) => (<>
          <FlatSelect
            disabled={
              sortIndex === SortIndex.SECONDARY &&
              (values[ SortIndex.PRIMARY ]?.colId == null ||
                values[ SortIndex.PRIMARY ]?.colId === '')
            }
            onChange={ (...args) => {
              onChange(...args);
              setValue(`${ sortIndex }.sort`, 'asc');
              applySorting?.(); 
            } }
            value={ value }
            className={ styles.select }
            options={ filteredOptions }
          />
        </>
        ) }
      />
      <Controller
        control={ control }
        name={ `${ sortIndex }.sort` }
        render={ ({ field: { onChange, value } }) => (
          <Button
            tooltip={ value === 'asc' ? 'A-Z' : 'Z-A' }
            disabled={ values[ sortIndex ]?.colId == null || values[ sortIndex ]?.colId === '' }
            className={ styles.sortButton }
            type='default'
            icon={ value === 'desc' ? <SortDescendingIcon /> : <SortAscendingIcon /> }
            onClick={ () => {
              onChange(value === 'desc' ? 'asc' : 'desc');
              applySorting?.();
            } }>
          </Button>
        ) }
     
      />
    </div>
  </>;
};

const emptySortSetting = {
  label: 'None',
  value: ''
};

const getEmptySortSettings = (sortIndex: SortIndex) => {
  return {
    colId: emptySortSetting.value,
    sort: 'desc' as SortType, 
    sortIndex
  };
};

export function convertColumnsStateToSortForm(columnsState: ColumnState[]): FormType {
  const primarySort = columnsState?.find(sort => sort.sortIndex === 0);
  const secondarySort = columnsState?.find(sort => sort.sortIndex === 1);

  return {
    [ SortIndex.PRIMARY ]: primarySort ? {
      ...primarySort,
      sortIndex: SortIndex.PRIMARY
    } : getEmptySortSettings(SortIndex.PRIMARY),
    [ SortIndex.SECONDARY ]: secondarySort ?{
      ...secondarySort,
      sortIndex: SortIndex.SECONDARY
    } : getEmptySortSettings(SortIndex.PRIMARY)
  };
}
