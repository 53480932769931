import React, { useCallback, useMemo } from 'react';
import { Redirect, TableColDef } from 'components/elements/tableWrapper/types/table.types';
import useTableColumns from 'components/elements/tableWrapper/hooks/useTableColumns';
import useTableRenderer from 'components/elements/tableWrapper/hooks/useTableRenderer';
import { useTranslation } from 'react-i18next';
import useTableFormatter from 'components/elements/tableWrapper/hooks/useTableFormatter';
import DatePickerCellEditor
  from 'components/elements/tableWrapper/table/editors/DatePickerCellEditor';
import { useAppSelector } from 'store/hooks/hooks';
import { Account, Counterparty } from 'types/statutory.types';
import NumericCellEditor from 'components/elements/tableWrapper/table/editors/NumericCellEditor';
import SelectCellEditor from 'components/elements/tableWrapper/table/editors/SelectCellEditor';
import TextCellEditor from 'components/elements/tableWrapper/table/editors/TextCellEditor';
import {
  CellValueChangedEvent,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  KeyCreatorParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { BudgetItem, BudgetItemAccounts, BudgetItemType } from 'types/budget.types';
import useTableEditableProps from 'components/elements/tableWrapper/hooks/useTableEditableProps';
import useCellValidators from 'components/elements/tableWrapper/hooks/useCellValidators';
import { Dimension, EXTERNAL_BREAKDOWN_TYPES } from 'types/filterTable.types';
import { InputRow } from 'components/inputs/types/inputs.types';
import { sectionKeys } from 'components/financials/detailedView/utils/detailedView.utils';
import { getDisplayName } from 'utils/common.utils';
import useAssignDimension from 'components/elements/tableWrapper/hooks/useAssignDimension';
import { useGetInputsQuery } from 'store/api/inputs.api';
import { arrayToMap } from '../../../../utils/mapping.utils';
import AccountCellEditor from '../../../elements/tableWrapper/table/editors/AccountCellEditor';
import ItemTypeBadge from '../../ItemTypeBadge/ItemTypeBadge';

import { ReactComponent as SplitIcon } from 'assets/icons/arrow-split.svg';
import FrequencyCellEditor
  from 'components/elements/tableWrapper/table/editors/FrequencyCellEditor';
import { FrequencyUnit } from 'types/financials.types';
import { frequencyPresets } from 'utils/financials.utils';

interface Props {
  search: string;
  updateBudgetItem: (budgetItem: BudgetItem) => void;
  setRedirect: React.Dispatch<Redirect>;
}

const NONE_COUNTERPARTY_ID = 0;
const INPUT_FIELD_PREFIX = 'input_';

const useBudgetItemTable = ({
  search,
  updateBudgetItem,
  setRedirect,
}: Props) => {
  const [ t ] = useTranslation('tableWrapper');
  const accounts = useAppSelector(state => state.breakdowns.accounts);
  const counterparties = useAppSelector(state => state.breakdowns.counterparties);
  const dimensionItemMap = useAppSelector(state => state.breakdowns.dimensionItemMap);
  const { data: inputsResponseData } = useGetInputsQuery({});
  const inputs = inputsResponseData?.results || [];
  const { budgetItemTypes, budgetItemTypesMap } = useAppSelector(state => state.budget);
  const {
    frequencyValueFormatter,
    percentageFormatter,
    frequencyFormatter,
  } = useTableFormatter();
  const { onAssignDimension } = useAssignDimension();
  const { renderField, defaultCellRenderer, amountRenderer } = useTableRenderer({ search });
  const {
    numberColumnDef,
    getDateColDef,
    getDimensionColumns,
    getRedirectColDef
  } = useTableColumns({ search });
  const { editableColumnDef } = useTableEditableProps();
  const {
    percentValidator,
    dateNumberValidator,
    monthOffsetValidator,
  } = useCellValidators();

  const accountsMap = useMemo(() => {
    return arrayToMap(accounts, 'id');
  }, [ accounts ]);

  const getValidatorsForInput = useCallback((input: InputRow) => {
    const rules = {
      optional: true
    };
    if (input.dataType === 'percentage') {
      return {
        ...rules,
        ...percentValidator,
        precision: 0,
      };
    }
    if (input.dataType === 'date') {
      return { ...rules, dateNumberValidator };
    }
    if ([ 'date_offset_days', 'date_offset_months' ].includes(input.dataType)) {
      return { ...rules, monthOffsetValidator };
    }
    return {};
  }, []);

  const getInputIdFromField = (field: string) => {
    return parseInt(field.split(INPUT_FIELD_PREFIX)[ 1 ]);
  };

  const inputSettingsValue = useCallback(() => {
    return {
      valueSetter: (params: ValueSetterParams<BudgetItem>) => {
        if (params.oldValue !== params.newValue) {
          const inputId = getInputIdFromField(params.colDef.field);
          updateBudgetItem({
            ...params.data,
            inputs: {
              ...params.data.inputs,
              [ inputId ]: params.newValue
            }
          });
        }

        return true;
      },
      valueGetter: (params: ValueGetterParams<BudgetItem>) => {
        if (params.node.group || params.node.isRowPinned()) return;

        const inputId = getInputIdFromField(params.colDef.field);

        const inputsData = params.data.inputs;

        if (inputsData && inputsData[ inputId ] != null ) {
          return inputsData[ inputId ];
        } else {

          const input = inputs.find(i => i.id === inputId);

          if (input.isVatPercentage) {
            // Special case for vatPercent. In this case we want default value from budget item type
            // instead of input, because it is different for different types of transactions.
            const budgetItemType = budgetItemTypes.find(
              item => item.id === params.data.itemType
            );
            if (!budgetItemType) return;
            if (budgetItemType.vatPercent !== null) {
              return budgetItemType.vatPercent;
            }
          }

          const defaultValue = inputs.find(i => i.id === inputId)?.defaultValue;
          if (defaultValue != null) {
            return defaultValue;
          }
        }
      }
    };
  }, []);

  const getBudgetSettingsProps = useCallback((input: InputRow): Partial<TableColDef> => {
    return ({
      width: 110,
      wrapHeaderText: true,
      flex: 0,
      valueFormatter: input && input.dataType === 'percentage' ? percentageFormatter : null,
      ...editableColumnDef,
      cellEditor: NumericCellEditor,
      cellEditorParams: {
        ...input ? getValidatorsForInput(input) : {},
      },
      ...numberColumnDef,
      sourceBadge: true,

      valueGetter: (params: ValueGetterParams<BudgetItem>) => {
        if (params.node.group || params.node.isRowPinned()) return;
        return params.data[ params.colDef.field ];
      }
    });
  }, []);

  const budgetColumnDefs: TableColDef[] = useMemo(() => ([
    {
      ...renderField('itemType', t('transactions-table.column.item-type'), 200),
      flex: 0,
      valueFormatter: (params: ValueFormatterParams<BudgetItemType>) => {
        return getDisplayName(budgetItemTypesMap[ params.value ]?.name);
      },
      valueSetter: (params: ValueSetterParams<BudgetItem>) => {
        updateBudgetItem({
          ...params.data,
          itemType: params.newValue.id
        });
        setRedirect({
          rowNodeIds: [ params.newValue.id ],
          sectionKey: getDisplayName(params.newValue.category),
          clear: () => setRedirect(null)
        });
        return true;
      },
      ...editableColumnDef,
      minWidth: 100,
      cellEditor: SelectCellEditor,
      cellRenderer: (props: ICellRendererParams) => {
        const itemType = budgetItemTypesMap[ props.value ];
        return <ItemTypeBadge itemType={ itemType } />;
      },
      cellEditorParams: {
        required: true,
        values: budgetItemTypes,
        formatValue: (value: BudgetItemType) => {
          const type = budgetItemTypesMap[ value.id ];
          return type ? getDisplayName(type.name) : '';
        }
      }
    },
    {
      ...renderField('split', t('transactions-table.column.split'), 80),
      flex: 0,
      sortable: false,
      cellRenderer: defaultCellRenderer,
      valueGetter: (_params: ValueGetterParams) => {
        if (_params?.data?.dimensionSplit) {
          if (_params.data.dimensionSplit.some(item => Number(item.percentage) !== 100))
            return <SplitIcon />;
        }
        return '';
      },
      suppressHide: false,
    },
    {
      ...renderField('accounts', t('transactions-table.column.account'), 200),
      flex: 0,
      keyCreator: (params: KeyCreatorParams<BudgetItem>) => {
        const primaryAccount = accountsMap[ params.value?.primary ];
        return `${ primaryAccount?.number } ${ getDisplayName(primaryAccount?.name) }`;
      },
      valueFormatter: (params: ValueFormatterParams<BudgetItemAccounts>) => {
        if (typeof params.value === 'string') return params.value;
        const primaryAccount = accountsMap[ params.value?.primary ];
        if (!primaryAccount) return '';
        return `${ primaryAccount?.number } ${ getDisplayName(primaryAccount?.name) }`;
      },
      valueGetter: (params: ValueGetterParams<BudgetItem>) => {
        return params.data?.accounts;
      },
      valueSetter: (params: ValueSetterParams<BudgetItem>) => {
        updateBudgetItem({
          ...params.data,
          accounts: {
            ...params.data.accounts,
            ...params.newValue,
          }
        });
        return true;
      },
      ...editableColumnDef,
      minWidth: 100,
      cellEditor: AccountCellEditor,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      cellEditorParams: {
        required: true,
        values: accounts,
        formatValue: (value: Account) => {
          return `${ value?.number } ${ getDisplayName(value?.name) }`;
        }
      }
    },
    {
      ...renderField('counterparty', t('transactions-table.column.counterparty'), 200),
      ...editableColumnDef,
      flex: 0,
      valueGetter: (params: ValueGetterParams<BudgetItem>) => params.data?.counterparty,
      cellEditor: SelectCellEditor,
      keyCreator: (params: KeyCreatorParams<BudgetItem>) => {
        return params.value?.name;
      },
      cellEditorParams: {
        values: [ { id: NONE_COUNTERPARTY_ID, name: t('common:none') }, ...counterparties ],
        formatValue: (value: Counterparty) => value?.name
      },
      valueFormatter: (params: ValueFormatterParams<Counterparty>) => {
        if (typeof params.value === 'string') return params.value;
        if (params.value?.name === t('common:none')) return '';
        return params.value?.name;
      },
      cellDataType: 'text',
    },
    {
      ...renderField('memo', t('transactions-table.column.memo'), 275),
      flex: 0,
      valueFormatter: (params: ValueFormatterParams<BudgetItem>) => {
        return params.value;
      },
      maxWidth: 500,
      suppressHide: true,
      ...editableColumnDef,
      cellEditor: TextCellEditor,
      cellEditorParams: {
        maxLength: 256
      },
      cellDataType: 'text',
    },
    {
      ...renderField('frequency', t('transactions-table.column.frequency'), 140),
      flex: 0,
      valueFormatter: frequencyValueFormatter,
      valueGetter: (params: ValueGetterParams<BudgetItem>) => {
        return {
          unit: params.data?.frequencyUnit ?? 'one_time',
          amount: params.data?.frequencyAmount ?? 1
        };
      },
      valueSetter: (params: ValueSetterParams<BudgetItem>) => {
        updateBudgetItem({
          ...params.data,
          frequencyUnit: params.newValue.unit,
          frequencyAmount: params.newValue.amount
        });

        return true;
      },
      getQuickFilterText: frequencyValueFormatter,
      ...editableColumnDef,
      cellEditor: FrequencyCellEditor,
      cellEditorParams: {
        required: true,
        dropdownMatchSelectWidth: false,
        values: frequencyPresets,
        formatValue: (value: { unit: FrequencyUnit; amount: number }) => frequencyFormatter(value)
      }
    },
    {
      ...renderField('amountFormula', t('transactions-table.column.amount'), 100),
      flex: 0,
      suppressHide: true,
      ...editableColumnDef,
      cellEditor: TextCellEditor,
      ...numberColumnDef,
      cellEditorParams: {
        required: true,
        disallowZero: true,
      },
      cellRenderer: amountRenderer,
      showTotal: true,
      onCellValueChanged: (e: CellValueChangedEvent) => {
        e.columnApi.autoSizeColumn('amount');
      },
      valueGetter: (params: ValueGetterParams<BudgetItem>) => params.data?.amountFormula
    },
    {
      ...getDateColDef('startDate', t('transactions-table.column.startDate')),
      ...editableColumnDef,
      flex: 0,
      cellEditor: DatePickerCellEditor,
      cellEditorPopup: true,
      cellEditorParams: {
        required: true,
        comparison: 'endDate',
        type: 'before',
      }
    },
    {
      ...getDateColDef('endDate', t('transactions-table.column.endDate')),
      ...editableColumnDef,
      flex: 0,
      cellEditor: DatePickerCellEditor,
      cellEditorPopup: true,
      cellEditorParams: {
        comparison: 'startDate',
        type: 'after',
      }
    },
  ]), [ counterparties, accounts, search ]);

  const budgetSettingsColumnDefs: TableColDef[] = useMemo(() => {
    const columns = [];

    for (const input of inputs.filter(i => i.canOverride)) {
      columns.push({
        ...renderField(INPUT_FIELD_PREFIX + input.id.toString(), getDisplayName(input.name), 220),
        ...getBudgetSettingsProps(input),
        ...inputSettingsValue(),
        flex: 0,
        cellEditorParams: {
          ...getValidatorsForInput(input)
        },
      });
    }
    return columns;
  }, [ search ]);

  const onChangeDimensionItem = useCallback(
    async (
      node: IRowNode<BudgetItem>,
      id: number,
      dimension: number
    ) => {

      // On change dimension item we also need to look at the splits.
      // If someone changes the dimension item, we will override the split values
      // for that dimension
      const updatedNode = onAssignDimension<BudgetItem>(node, id, dimension);
      updatedNode.dimensionSplit = updatedNode.dimensionSplit.filter(
        split => {
          const splitDimension = dimensionItemMap[ split.dimensionItem ];
          return splitDimension?.dimensionId !== dimension;
        }
      );
      if (id !== 0) {
        // 0 is passed when unassigning, so we should only remove.
        updatedNode.dimensionSplit.push({
          dimensionItem: id,
          percentage: 100
        });
      }

      updateBudgetItem(updatedNode);
    }, [ onAssignDimension ]);

  const canBeAssigned = useCallback((_: EditableCallbackParams, dimension: Dimension) => {
    return dimension.canBeAssigned || EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type);
  }, []);

  const canBeUnassigned = useCallback((_: ICellEditorParams, dimension: Dimension) => {
    return dimension.canBeUnassigned || EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type);
  }, []);

  const dimensionColumnDefs: TableColDef[] = useMemo(() => {
    return getDimensionColumns(
      {
        onChangeDimensionItem,
        canBeAssigned,
        canBeUnassigned,
        removeRelationColumns: true }
    );
  }, [ getDimensionColumns, onChangeDimensionItem ]);

  const redirectColDef: TableColDef = useMemo(() => {
    return getRedirectColDef('transactionLines', (node: IRowNode<BudgetItem>) => {
      setRedirect({
        rowNodeIds: node.data.transactionLines,
        sectionKey: sectionKeys.transactions,
        clear: () => setRedirect(null)
      });
    });
  }, []);

  return {
    budgetColumnDefs,
    budgetSettingsColumnDefs,
    dimensionColumnDefs,
    redirectColDef,
  };
};

export default useBudgetItemTable;
