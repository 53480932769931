import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'locales/i18n';

import { ErrorMessage as ErrorMessage } from 'types/form.types';
import { getValidationRules } from 'utils/form.utils';
import { login } from 'services/auth.service';
import { loginUser } from 'store/auth.slice';
import { routes } from 'utils/config.utils';

import Input from 'components/elements/input/Input';
import Button from 'components/elements/button/Button';
import Checkbox from 'components/elements/checkbox/Checkbox';
import AuthCard from 'components/auth/authCard/AuthCard';
import Loader from 'components/elements/loader/Loader';

import styles from './Login.module.scss';
import { useAppDispatch } from '../../../store/hooks/hooks';

const inputs = [
  {
    name: 'email',
    placeholder: 'name@company.com',
    label: i18n.t('input-labels.email', { ns: 'auth' }),
    rules: getValidationRules(
      { required: true, maxLength: 255, isEmail: true },
      i18n.t('input-labels.email', { ns: 'auth' }),
    ),
  },
  {
    name: 'password',
    label:i18n.t('input-labels.password', { ns: 'auth' }),
    type: 'password',
    rules: getValidationRules(
      { required: true, maxLength: 128 },
      i18n.t('input-labels.password', { ns: 'auth' }),
    ),
  }
];

const Login: React.FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [ errorMessage, setErrorMessage ] = useState<ErrorMessage>();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ t ] = useTranslation('auth');

  const dispatch = useAppDispatch();

  const onSubmit = (values) => {
    setIsLoading(true);
    login(values)
      .then(({ data }) => {
        dispatch(loginUser(data, values.remember));
      })
      .catch(({ response }) => {
        if (response.data.nonFieldErrors[ 0 ] === 
            'Unable to log in with provided credentials.') {
          setErrorMessage({
            message: t('notifications.credentials.message'),
            description: t('notifications.credentials.description')
          });
        } else {
          setErrorMessage({ 
            message: t('notifications.unexpected-error.message', { ns: 'common' }), 
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const error = Object.values(errors)?.[ 0 ];
    if (error) {
      setErrorMessage({ message: error.message as string });
    } else {
      errorMessage && setErrorMessage(null);
    }
  }, [ errors.email, errors.password ]);

  return (
    <AuthCard containerClassName={ styles.loginPage }>
      <h1 className={ styles.header }>{ t('buttons.login') }</h1>
      <form
        onSubmit={ handleSubmit(onSubmit) }
        className={ styles.form }
      >

        { errorMessage && (
          <Alert
            message={ errorMessage.message }
            description={ errorMessage.description }
            type='error'
            showIcon
            className={ styles.alert }
          />
        ) }
        { inputs.map(input => (
          <Input
            key={ input.name }
            control={ control }
            errors={ errors }
            className={ styles.input }
            { ...input }
          />
        )) }
        <div className={ styles.grid }>
          <Checkbox
            name='remember'
            label={ t('buttons.remember') }
            control={ control }
            errors={ errors }
          />
          <Link to={ routes.forgotPassword } className={ styles.link }>
            { t('buttons.forgot') }
          </Link>
        </div>
        <Button
          size='large'
          name={ t('buttons.login') }
          className={ styles.button }
          htmlType='submit'
        />
      </form>
      <Loader isActive={ isLoading }/>
    </AuthCard>
  );
};

export default Login;
