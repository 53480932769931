import React, { useState } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import OptionPopover from '../optionPopover/OptionPopover';

import { ReactComponent as RecurringIcon } from 'assets/icons/recurring.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import styles from '../BudgetDropdown.module.scss';
import BudgetOption from '../budgetSelectedOption/BudgetSelectedOption';
import { getBudgetingOptionKey } from 'components/financials/utils/addRow.utils';
import clsx from 'clsx';
import useTableFormatter from 'components/elements/tableWrapper/hooks/useTableFormatter';
import { frequencyPresets } from 'utils/financials.utils';
import { MonthInput } from 'components/elements/tableWrapper/table/editors/FrequencyCellEditor';
import { FrequencyUnit } from 'types/financials.types';

type FrequencySettingsOptions = typeof frequencyPresets[number] | {
  name: 'custom';
  amount: number;
  unit: FrequencyUnit;
  label: string;
};

const FrequencyOptions = () => {
  const [ t ] = useTranslation();

  const templateId = useAppSelector(state => state.financials.active.templateId);
  const frequencyKey = getBudgetingOptionKey('frequency', templateId);

  const { frequencyFormatter } = useTableFormatter();

  const [ frequency, setFrequency ] = useLocalStorage<FrequencySettingsOptions>(
    frequencyKey, frequencyPresets.at(0)
  );

  const [ customFrequency ] = useState(
    frequency.name === 'custom' ? frequency.amount : 1
  );

  return <OptionPopover
    content={ <>
      <div className={ styles.frequencyOptionList }>
        {
          frequencyPresets
            .map((item) => {
              return <div
                key={ item.name }
                className={ clsx(
                  styles.optionItem,
                  { [ styles.selectedOption ]: frequency?.name === item?.name }
                ) }
                onClick={ () => setFrequency(item) }
              >
                { frequencyFormatter(item) }
                {
                  frequency?.name === item?.name ?
                    <CheckIcon className={ styles.selectedIcon } /> :
                    null
                }
              </div>;
            })
        }
        <div 
          className={ clsx(
            styles.optionItem,
            { [ styles.selectedOption ]: frequency.name === 'custom' }
          ) }
          onClick={ () => setFrequency({
            name: 'custom',
            amount: customFrequency,
            unit: 'month',
            label: ''
          }) }
        >
          <MonthInput value={ customFrequency } onChange={ (value) => setFrequency({
            name: 'custom',
            amount: value,
            unit: 'month',
            label: ''
          }) } />
          {
            frequency?.name === 'custom' ?
              <CheckIcon className={ styles.selectedIcon } /> :
              null
          }
        </div>
        
      </div>
    </> }
  >
    <BudgetOption
      name={ t('financials:inline-edit.fields.frequency') }
      icon={ <RecurringIcon /> }
      value={ frequencyFormatter(frequency) }
    />
  </OptionPopover>;
};

export default FrequencyOptions;