import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { useTranslation } from 'react-i18next';
import { RowType } from 'types/financials.types';
import { BudgetItemUpdateRequest } from 'types/budget.types';
import { planningService } from 'services/planning.service';
import { notifyError, notifyUnexpectedError } from 'utils/notifications.utils';
import { financialsSlice, selectPeriod } from 'store/financials.slice';
import dayjs from 'dayjs';
import {
  durationMap
} from 'components/elements/tableInlineEdit/budgetItemPopover/useFieldDefinitions';
import useReportQuery from '../useReportQuery';
import { isNewRowWithValue } from 'components/financials/utils/addRow.utils';
import { DimensionSplitParams } from 'types/templates.types';
import { DimensionItem } from 'types/filterTable.types';
import { updateBudgetGeneration } from 'store/budget.slice';

import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';

import styles from '../../styles/FinancialTable.module.scss';

interface Props {
  templateId: number;
  gridRef: React.MutableRefObject<AgGridReact>;
  isTableEdited: object | null;
}

const saveUtil = defaults.find(u => u.key === 'save');

const useGenerateTable = ({ templateId, gridRef, isTableEdited }: Props) => {
  const [ t ] = useTranslation('financials');

  const dispatch = useAppDispatch();

  const [ isUpdating, setUpdating ] = useState(false);

  const generateTable = async () => {
    const newNodes: BudgetItemUpdateRequest[] = gridRef.current.api.getRenderedNodes()
      .filter(node => node?.data?.type === RowType.NEW_BUDGET_ITEM)
      .map((node) => {
        return Object.values(node.data.rowData.entries).map((entry: object) => ({
          ...node,
          data: {
            ...node.data,
            rowData: {
              ...node.data.rowData,
              ...entry
            }
          }
        }));
      })
      .flat()
      .filter(node => {
        return node.data.rowData.amountFormula;
      })
      .map((node) => {
        const rowData = node.data.rowData;
        const amountInRow = Object.values(node.data.plan).at(0);
        const dateInRow = Object.keys(node.data.plan).at(0);

        let endDate = rowData.endDate;

        if (!endDate && rowData.duration?.id) {
          endDate = dayjs(rowData.startDate)
            .add(durationMap[ rowData.duration.id ] - 1, 'month').format('YYYY-MM-DD');
        } 

        const inputs = {};

        for (const key in rowData) {
          if (key.startsWith('input')) {
            const [ , inputKey ] = key.split('_');

            inputs[ inputKey ] = rowData[ key ];
          }
        }
        const splitsFromDimensionItems: DimensionSplitParams[] =
          rowData?.dimensionItems.map((item: DimensionItem) => {
            return {
              dimensionItem: item.id,
              percentage: 100
            };
          });

        const newBI: BudgetItemUpdateRequest = {
          itemType: rowData?.budgetItemType?.id,
          accounts: rowData?.accounts ?? rowData?.budgetItemType?.accounts,
          amountFormula: rowData?.amountFormula ?? amountInRow,
          memo: rowData?.memo,
          startDate: dayjs(rowData?.startDate ?? dateInRow).format('YYYY-MM-DD'),
          endDate: endDate,
          frequencyUnit: rowData?.frequencyUnit ?? 'one_time',
          frequencyAmount: rowData?.frequencyAmount,
          dimensionSplit: splitsFromDimensionItems,
          counterparty: rowData?.counterparty?.id,
          inputs
        };

        return newBI;
      });

    setUpdating(true);

    try {
      dispatch(updateBudgetGeneration({
        status: 'queued',
      }));
      await planningService.batchUpdateBudgetItems(newNodes);

    } catch (e) {
      dispatch(updateBudgetGeneration({
        status: 'not_started',
      }));
      const key = Object.keys(e.response.data)[ 0 ];
      if (key) {
        const values = Object.values(e.response.data[ key ]);
        if (values) {
          notifyError(values[ 0 ] as unknown as string);
        }
      } else {
        notifyUnexpectedError(e);
      }
    }

    setUpdating(false);

  };

  useEffect(() => {
    dispatch(financialsSlice.actions.setHasNewCells({
      templateId,
      value: gridRef.current?.api?.getRenderedNodes().some(node => isNewRowWithValue(node?.data))
    }
    ));
  }, [ isTableEdited ]);

  const isTableActive = useAppSelector(state => {
    const active = state.financials.active;
    return active.templateId === templateId && active.type === 'table';
  });

  const period = useAppSelector(selectPeriod(templateId));

  const { refetch } = useReportQuery({ templateId,
    period,
    shouldFetch: true
  });

  const onRefresh = () => {
    refetch();
  };

  const refreshButton: CommandBarUtil = useMemo(() => {
    const canUpdate = gridRef.current?.api?.getRenderedNodes()
      .some(node => isNewRowWithValue(node?.data));

    if (canUpdate) {
      return {
        ...saveUtil,
        disabled: isUpdating,
        hidden: false,
        name: <div className={ styles.refreshButton }>
          <RefreshIcon className={ styles.refreshIcon } />
          <span>{ t('command-bar.update') }</span>
        </div>,
        active: canUpdate,
        type: 'button-text',
        buttonType: 'primary',
        tooltip: t('command-bar.update-tooltip'),
        onClick: async () => generateTable()
      };
    } else {
      return {
        ...saveUtil,
        disabled: false,
        hidden: false,
        name: <div className={ styles.refreshButton }>
          <RefreshIcon className={ styles.refreshIcon } />
          <span>{ t('command-bar.refresh') }</span>
        </div>,
        active: true,
        type: 'button-text',
        buttonType: 'default',
        tooltip: t('command-bar.refresh-tooltip'),
        onClick: () => onRefresh()
      };
    }

  }, [ isTableEdited, isUpdating ]);

  const utilsToUpdate: CommandBarUtil[] = useMemo(() => {
    if (!isTableActive) {
      return [];
    }
    return [ refreshButton ];
  }, [ isTableActive, refreshButton ]);

  return useCommandBarEffect({
    utils: utilsToUpdate,
    hidden: false,
    clearOnUnmount: false
  });
};

export default useGenerateTable;
