import { DimensionItemWithSplit, Transaction } from 'types/statutory.types';
import { BudgetItem, BudgetItemSplit } from 'types/budget.types';
import dayjs from 'dayjs';
import { DATE_FORMATS } from 'utils/date.utils';
import { Dimension, DimensionItem } from 'types/filterTable.types';
import { ItemToMap } from 'utils/mapping.utils';
import { groupBy } from 'lodash';
import {
  isTransactionLineRequestParams,
  TransactionLineRequestParams
} from '../../../../services/statutory.service';

export const CURRENCY_SYMBOL = '€';

export function areTransactions (items: (Transaction | BudgetItem)[]): items is Transaction[] {
  return items.every(isTransaction);
}

export function isTransaction (
  item?: Transaction | BudgetItem | TransactionLineRequestParams): item is Transaction {
  return (item as Transaction)?.transaction !== undefined;
}

export function isSplitDimensionItem ({ percentage }: { percentage: number | string }) {
  return Number(percentage) !== 100;
}

export function getLabel (splitItem: Transaction | BudgetItem | TransactionLineRequestParams) {
  if (isTransactionLineRequestParams(splitItem)) {
    return 'All selected';
  }
  const date = isTransaction(splitItem) ? splitItem.transactionDate : splitItem.startDate;
  const tlDate = dayjs(date).format(DATE_FORMATS.at(0));
  const tlCounterparty = splitItem.counterparty ? (splitItem.counterparty.name + ',') : '';

  return `${ tlDate + ',' } ${ tlCounterparty } ${ splitItem.memo }`;
}

export function addDimensionId(dimensionsItemsMap: ItemToMap<DimensionItem>) {
  return (item: BudgetItemSplit | DimensionItemWithSplit) => {
    const dimensionItemData = dimensionsItemsMap[ item.dimensionItem ];

    return {
      ...item,
      dimensionItem: dimensionItemData.id,
      dimension: dimensionItemData.dimensionId,
      percentage: item.percentage
    };
  };
}

function getSplits(splitItem: (Transaction | BudgetItem)):
(BudgetItemSplit | DimensionItemWithSplit)[] {
  if (isTransaction(splitItem)) {
    return splitItem.dimensionItems;
  } else {
    return splitItem?.dimensionSplit ?? [];
  }
}

function getDimensionSplit(splitItem: (Transaction | BudgetItem)):
(Omit<BudgetItemSplit, 'percentage'> | Omit<DimensionItemWithSplit, 'percentage' | 'value'>)[] {
  if (isTransaction(splitItem)) {
    // Filter out dimension duplicates
    return splitItem.dimensionItems
      .filter(isSplitDimensionItem)
      .filter((item, index, self) =>
        index === self.findIndex((t) => t.dimension === item.dimension)
      );
  } else {
    return splitItem?.dimensionSplit?.filter(isSplitDimensionItem)
      ?.filter((item, index, self) => index === self.findIndex((t) => {
        // TODO: Type verification needed
        const tDimensionItem = (splitItem.dimensionItems as unknown as DimensionItem[])
          .find(localDI => localDI.id === t.dimensionItem);
        const itemDimensionItem = (splitItem.dimensionItems as unknown as DimensionItem[])
          .find(localDI => localDI.id === item.dimensionItem);
        return tDimensionItem.dimensionId === itemDimensionItem.dimensionId;
      })) ?? [];
  }
}

export function getItemsWithSplits (items: (Transaction | BudgetItem)[]) {
  return items.filter(item => getSplits(item)?.length > 0);
}

export function getExistingSplits(splitItems: (Transaction | BudgetItem)[]) {
  return splitItems.map(item => getSplits(item)?.filter(isSplitDimensionItem)).flat();
}

export function getDimensionSplits (splitItems: (Transaction | BudgetItem)[]) {
  return splitItems.map(item => getDimensionSplit(item)).flat();
}

export function groupSplitItemByDimension(
  items: (Transaction | BudgetItem)[],
  dimensionItemMap: ItemToMap<DimensionItem>
) {
  const splitsWithItemId = items.reduce<
  { dimension: number | null; splitItemId: number }[]
  >((acc, item) => {
    return [
      ...acc,
      ...splitItemToDimension(item, dimensionItemMap)
    ];
  }, []);

  return groupBy(splitsWithItemId, 'dimension');
}

function splitItemToDimension(
  splitItem: Transaction | BudgetItem,
  dimensionItemMap: ItemToMap<DimensionItem>
) {
  const splitDimensionItems = getExistingSplits([ splitItem ]);
  
  if (splitDimensionItems.length === 0) {
    return [];
  }

  return uniqueBy(
    splitDimensionItems.map(addDimensionId(dimensionItemMap)),
    'dimension'
  ).map(item => ({ dimension: item.dimension, splitItemId: splitItem.id }));
}

export function uniqueBy<T>(items: T[], attr: string | ((a: T, b: T) => boolean)): T[] {
  return items.filter((item, index, self) => (
    index === self.findIndex((t) => {
      if (typeof attr === 'function') {
        return attr(t, item);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (t as any)?.[ attr ] === (item as any)?.[ attr ];
    })
  ));
}

export function isAnyNaN (items: (number | string)[]) {
  return items.some(item => isNaN(Number(item)));
}

export function getUnassignableBudgetItemSplitsForDelete(
  split: BudgetItem,
  dimensionItemMap: ItemToMap<DimensionItem>,
  dimensionMap: ItemToMap<Dimension>
) {
  const dimensionToBIMap = new WeakMap<BudgetItemSplit, Dimension>();

  return split.dimensionSplit
    .filter(dimensionSplit => Number(dimensionSplit.percentage) !== 100)
    .filter(dimensionSplit => {
      const dimensionItem = dimensionItemMap[ dimensionSplit.dimensionItem ];
      const dimension = dimensionMap[ dimensionItem.dimensionId ];
      if (dimension && !dimension.canBeUnassigned) {
        dimensionToBIMap.set(dimensionSplit, dimension);
        return true;
      }
      return false;
    })
    .reduce((acc, dimensionSplit) => {
      const existingIndex = acc.findIndex(d =>
        dimensionToBIMap.get(d).id === dimensionToBIMap.get(dimensionSplit).id);
      if (existingIndex === -1) {
        acc.push(dimensionSplit);
      } else {
        if (Number(acc[ existingIndex ].percentage) < Number(dimensionSplit.percentage)) {
          acc[ existingIndex ] = dimensionSplit;
        }
      }
    
      return acc;
    }, [])
    .map((dimensionSplit) => ({
      ...dimensionSplit,
      dimension: dimensionToBIMap.get(dimensionSplit).id,
      percentage: 100 }));
}

/**
 * Since some dimension items cannot be simply unassigned, we need to fully assign
 * one dimension item in order to delete split, but keep any assignment.
 * @param splits
 * @param dimensionMap
 */
export function getUnassignableTransactionSplitsForDelete(
  splits: ( Transaction)[], dimensionMap: ItemToMap<Dimension>
) {
  const unassignableSplits = splits.map((item) => {
    return {
      transactionLine: item.id,
      splits: item.dimensionItems
        .filter(dimensionItem => Number(dimensionItem.percentage) !== 100)
        .filter(dimensionItem => {
          return dimensionMap[ dimensionItem.dimension ] &&
            !dimensionMap[ dimensionItem.dimension ].canBeUnassigned;
        }).reduce((acc, dimensionItem) => {
          const existingIndex = acc.findIndex(d => d.dimension === dimensionItem.dimension);
          if (existingIndex === -1) {
            acc.push(dimensionItem);
          } else {
            if (Number(acc[ existingIndex ].percentage) < Number(dimensionItem.percentage)) {
              acc[ existingIndex ] = dimensionItem;
            }
          }
        
          return acc;
        }, [])
    };
  })
    .filter(item => item.splits.length > 0);

  if (unassignableSplits.length > 0) {
    const groupedByDimensionItem = unassignableSplits.reduce((acc, item) => {
      item.splits.forEach(split => {
        const existingIndex = acc.findIndex(d => d.dimensionItem === split.dimensionItem);
        if (existingIndex !== -1) {
          acc[ existingIndex ] = {
            ...split,
            transactionLines: [
              ...(acc[ existingIndex ]?.transactionLines ?? []), item.transactionLine
            ]
          };
        } else {
          acc.push({
            ...split,
            transactionLines: [ item.transactionLine ]
          });
        }
      });

      return acc;
    }, []);

    return groupedByDimensionItem.map(item => {
      return ({
        transactionLines: item.transactionLines,
        split: [ {
          dimension: item.dimension,
          dimensionItem: item.dimensionItem,
          percentage: 100
        } ]
      });
    });
  }

  return [];
}

export function balanceValues(_values: number[]): number[] {
  const values = _values.map(value => Math.min(100, Math.max(0, value)))
    .map((value) => {
      if (isNaN(value)) {
        return 0;
      }
      return value;
    });

  const total = values.reduce((sum, val) => sum + val, 0); // Calculate the sum of all values
  let difference = total - 100; // Calculate how much the total exceeds or falls short of 100

  // Handle case where the sum exceeds 100 (reduce values)
  if (difference > 0) {
    for (let i = values.length - 1; i >= 0 && difference > 0; i--) {
      const decrease = Math.min(values[ i ], difference);
      values[ i ] -= decrease;
      difference -= decrease;
    }
  }

  // Handle case where the sum is less than 100 (increase values)
  if (difference < 0) {
    for (let i = values.length - 1; i >= 0 && difference < 0; i--) {
      if (values[ i ] < 100) {
        const increase = Math.min(100 - values[ i ], -difference);
        values[ i ] += increase;
        difference += increase;
      }
    }
  }

  return values;
}
