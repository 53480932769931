import React, { useCallback, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ReactComponent as ActiveIcon } from 'assets/icons/contracts/active.svg';
import { ReactComponent as PastIcon } from 'assets/icons/contracts/planned.svg';
import { LabelingStatus } from 'types/labeling.types';
import Tooltip from 'components/elements/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import styles from '../../LabelingRules.module.scss';
import HighlightedText from '../../../elements/highlightedText/HighlightedText';
import Pill from '../../../elements/pill/Pill';
import { TableColDef } from '../../../elements/tableWrapper/types/table.types';
import useTableFormatter from '../../../elements/tableWrapper/hooks/useTableFormatter';
import useTableRenderer from '../../../elements/tableWrapper/hooks/useTableRenderer';
import useTableColumns from '../../../elements/tableWrapper/hooks/useTableColumns';
import { DATE_FORMATS } from '../../../../utils/date.utils';

type Props = {
  setTransactionListClicked: (id: number) => void;
  search: string;
};

const useRulesGrid = ({ setTransactionListClicked, search }: Props) => {
  const [ t ] = useTranslation('labeling');
  const { timeDistanceFormatter } = useTableFormatter();
  const { numberColumnDef, getDateColDef } = useTableColumns({ search });
  const { renderField } = useTableRenderer({ search });

  const getDimensionCellRenderer = useCallback(
    (titleField: string, { value }: ICellRendererParams) => {
      return <div className={ styles.cellRenderer }>
        <Pill>
          <HighlightedText
            text={ value }
            highlight={ search }
          />
        </Pill>
      </div>;
    }, [ search ]);

  const getStatusIcon = useCallback((status: string) => {
    return {
      [ LabelingStatus.ACTIVE ]: <ActiveIcon/>,
      [ LabelingStatus.ENDED ]: <PastIcon className={ styles.grayIcon }/>
    }[ status ];
  }, []);

  const statusCellRenderer = useCallback(({ value, data }) => {
    let startDate;
    let endDate;
    if (data) {
      startDate = data.startDate;
      endDate = data.endDate;
    }

    return <div className={ styles.statusCell }>
      <Tooltip title={ <div>
        {
          startDate?.isValid() && (<div>
            { `${ t('table.start-date') }: ${ startDate?.format(DATE_FORMATS[ 0 ]) }` }
          </div>)
        }
        {
          endDate?.isValid() && (<div>
            { `${ t('table.end-date') }: ${ endDate?.format(DATE_FORMATS[ 0 
            ]) }` }
          </div>)
        }
      </div> }>
        { getStatusIcon(value) }
      </Tooltip>
    </div>;
  }, []);

  const actionsTransactionsRenderer = useCallback((params: ICellRendererParams) => {
    return (
      <>
        <Tooltip title={ t('open-tl-list') }>
          <div
            onClick={ () => {
              setTransactionListClicked(params.node.data.id);
            } }
            className={ styles.actionsList }>
            { t('table.view') }
          </div>
        </Tooltip>
      </>
    );
  }, []);

  const columnDefs: TableColDef[] = useMemo(() => ([
    {
      ...renderField('sourceDimension', t('table.column.source-label')),
      wrapHeaderText: true,
      flex: 1,
      cellDataType: 'text',
    },
    {
      ...renderField('source', t('table.column.source-dimension'), 120),
      flex: 1,
      wrapHeaderText: true,
      sortable: false,
      cellDataType: 'text',
    },
    {
      ...renderField('target', t('table.column.target-dimension'), 150),
      flex: 1,
      hide: false,
      cellDataType: 'text',
    },
    {
      ...renderField('targetDimension', t('table.column.target-label')),
      getQuickFilterText: params => params.value,
      cellRenderer: params => getDimensionCellRenderer('target', params),
      flex: 1,
      cellDataType: 'text',
    },
    {
      ...renderField('applied', t('table.column.applied'), 120),
      flex: 0,
      wrapHeaderText: true,
      cellDataType: 'number',
      ...numberColumnDef
    },
    {
      ...getDateColDef('startDate', t('table.column.startDate')),
      cellDataType: 'date',
    },
    {
      ...getDateColDef('endDate', t('table.column.endDate')),
      cellDataType: 'date',
    },
    {
      ...renderField('lastApplied', t('table.column.last-applied'), 105),
      valueFormatter: timeDistanceFormatter,
      getQuickFilterText: params => timeDistanceFormatter(params),
      flex: 0,
      wrapHeaderText: true,
      cellDataType: 'number',
    },
    {
      ...renderField('ruleType', t('table.column.rule-type'), 100),
      flex: 0,
      wrapHeaderText: true,
      cellDataType: 'text',
    },
    {
      ...renderField('status', t('table.column.status'), 100),
      cellRenderer: statusCellRenderer,
      flex: 0,
      cellDataType: 'text',
    },
    {
      ...renderField('action-list', t('table.column.transaction-lines'), 120),
      flex: 0,
      cellRenderer: actionsTransactionsRenderer,
      wrapHeaderText: true,
      sortable: false,
    },
  ]), [ search ]);

  return {
    columnDefs,
  };
};

export default useRulesGrid;
