import React, { useCallback, useMemo } from 'react';
import {
  ColDef,
  ICellRendererParams,
  IRowNode,
  RowClassRules,
} from 'ag-grid-community';
import styles from '../table/Table.module.scss';
import { useTranslation } from 'react-i18next';

const useTableEditableProps = () => {
  const [ t ] = useTranslation('tableWrapper');

  const editable = useCallback((node: IRowNode) => {
    return node.rowPinned !== 'bottom' && !node.group;
  }, []);

  const editableColumnDef: ColDef = useMemo(() => ({
    editable: (params) => editable(params.node),
    suppressKeyboardEvent: (params) => {
      if (params.editing && params.event.key === 'Escape') {
        params.event.stopPropagation();
      }
      return false;
      
    },
    singleClickEdit: true,
  }), []);

  const isNewNodeRow = useCallback((node: IRowNode) => {
    return node.data?.internalId != null;
  }, []);

  const rowClassRules: RowClassRules = useMemo(() => ({
    [ styles.newRow ]: (params) => isNewNodeRow(params.node),
  }), []);

  const checkboxColDef: ColDef = useMemo(() => ({
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    field: 'checkbox-selection',
    cellClass: styles.checkboxCell,
    headerClass: styles.checkboxHeader,
    headerName: '',
    width: 50,
    maxWidth: 50,
    minWidth: 50,
    resizable: false,
    suppressMovable: true,
    pinned: 'left',
    lockPosition: 'left',
    cellRenderer: (params: ICellRendererParams) => {
      if (isNewNodeRow(params.node)) {
        return <div className={ styles.newRowBadge }>
          { t('checkbox.new-row') }
        </div>;
      }

      return undefined;
    }
  }), []);

  return {
    rowClassRules,
    checkboxColDef,
    editableColumnDef
  };
};

export default useTableEditableProps;
