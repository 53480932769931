import styles from '../TopBar.module.scss';
import FlatSelect from '../../../elements/flatSelect/FlatSelect';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';

export const REPORT_MODE_KEY = 'reportMode';

export const ReportModeDropdown = () => {

  const [ t ] = useTranslation('financials');

  const [ currentReportMode, setCurrentReportMode ] = useLocalStorage(
    REPORT_MODE_KEY, 'accounting');

  const options = [
    { value: 'accounting', label: t('report-modes.accounting') },
    { value: 'cash', label: t('report-modes.cash') },
    { value: 'invoice', label: t('report-modes.invoice') },
  ];

  const onReportModeChange = useCallback((e) => {
    setCurrentReportMode(e);
  }, []);

  return <FlatSelect
    className={ styles.viewSelect }
    options={ options }
    defaultValue={ currentReportMode }
    onChange={ (e) => {
      onReportModeChange(e);
    } }
  />;
};
