import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { tokenRefresh } from 'services/auth.service';
import { apiUrl } from 'utils/config.utils';
import {
  getActiveBudgetIdCookie,
  getActiveOrganizationIdCookie,
  getRefreshToken,
  getToken,
  handleLogout,
  setTokens
} from 'utils/auth.utils';
import qs from 'qs';
import { REPORT_MODE_KEY } from '../components/layout/topBar/reportModeDropdown/ReportModeDropdown';

const http: AxiosInstance = axios.create({
  paramsSerializer: params => qs.stringify(params),
  baseURL: apiUrl,
  headers: { 'Content-Type': 'application/json' },
});

export const httpNoInterceptors: AxiosInstance = axios.create({
  baseURL: apiUrl,
  headers: { 'Content-Type': 'application/json' },
});

export const getActiveReportMode = (): string | null => {
  const reportModeValue = localStorage.getItem(REPORT_MODE_KEY);
  if (reportModeValue) {
    return reportModeValue.replaceAll('"', '');
  }
  return null;
};

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const key = getToken();
    const activeOrganizationId = getActiveOrganizationIdCookie();
    const activeBudgetId = getActiveBudgetIdCookie();
    const activeReportMode = getActiveReportMode();
    if (config?.headers) {
      key && (config.headers.Authorization = `Bearer ${ key }`);
      activeOrganizationId && (config.headers[ 'X-Organization-ID' ] = activeOrganizationId);
      activeReportMode && (config.headers[ 'X-Report-Mode' ] = activeReportMode);
      activeBudgetId && (config.headers[ 'X-Budget-ID' ] = activeBudgetId.toString());
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

const requestQueue: { error: AxiosError; resolve: (value) => void; reject: () => void }[] = [];

const executeQueue = async (accessToken: string) => {
  while (requestQueue.length) {
    const { error, resolve, reject } = requestQueue.shift();
    const updatedConfig = error.config;
    if (updatedConfig?.headers) {
      updatedConfig.headers.Authorization = `Bearer ${ accessToken }`;
    }
    try {
      resolve(await axios.request(updatedConfig));
    } catch (err) {
      reject();
    }
  }
};

const refreshAccessToken = () => {
  const rToken = getRefreshToken();
  return tokenRefresh(rToken)
    .then(({ data }) => {
      setTokens(data.access, data.refresh);
      return executeQueue(data.access);
    })
    .catch((err) => {
      handleLogout();
      return Promise.reject(err);
    });
};

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const status = error?.response?.status;
    if (status === 401) {
      return new Promise((resolve, reject) => {
        requestQueue.push({ error, resolve, reject });
        if (requestQueue.length === 1) {
          refreshAccessToken();
        }
      });
    }

    return Promise.reject(error);
  },
);

export default http;
