import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { BudgetItem } from 'types/budget.types';
import { Transaction } from 'types/statutory.types';
import { addDimensionId, getExistingSplits, uniqueBy } from './split.utils';
import { emptyDimension } from './splits.constants';
import { sumLabels } from './validation';
import {
  isTransactionLineRequestParams,
  TransactionLineRequestParams
} from '../../../../services/statutory.service';
import organizationsService from '../../../../services/organizations.service';
import { FormType } from './dimensionSplit.types';

const useSplitItemDefault = () => {
  const [ t ] = useTranslation('financials');
  const dimensionsItemsMap = useAppSelector(state => state.breakdowns.dimensionItemMap);

  const getMultipleDimensionLabel = (dimensionId: number | string) => {

    const getLabel = () => ({
      dimensionItemId: t('dimension-split.modal.multipleLabel'),
      draft: false,
      nominalValue: 0,
      percentage: 0
    });

    return {
      dimension: Number(dimensionId),
      labels: [
        getLabel(),
        getLabel(),
      ],
      draft: true
    };
  };

  return async (
    items: (TransactionLineRequestParams | Transaction | BudgetItem)[],
    nominalValue?: number | null
  ): Promise<FormType> => {

    if (isTransactionLineRequestParams(items[ 0 ])) {
      const splitState = await organizationsService.getDimensionSplitState(
        { filters: items as TransactionLineRequestParams[] }
      );
      const grouped = groupBy(splitState.data.splits, 'dimension');
      if (Object.keys(grouped).length === 0) {
        return { dimensions: [ emptyDimension ] };
      }

      return {
        dimensions: Object.entries(grouped).map(([ dimension, dimeWithSplit ]) => {
          if (dimeWithSplit[ 0 ].state === 'common') {
            return {
              dimension: Number(dimension),
              labels: dimeWithSplit.map((item) => ({
                dimensionItemId: item.dimensionItem,
                percentage: Number(item.percentage),
                nominalValue: (+(item.percentage) / 100) * nominalValue,
                draft: false
              })),
              draft: false
            };
          } else {
            return getMultipleDimensionLabel(dimension);
          }

        })
      };
    } else {
      if (items?.length) {
        const splits = getExistingSplits(items as Transaction[] | BudgetItem[])
          .map(addDimensionId(dimensionsItemsMap));

        if (splits.length === 0) {
          return { dimensions: [ emptyDimension ] };
        }

        const groupedByDimension = groupBy(splits, 'dimension');

        const dimensions = Object
          .entries(groupedByDimension)
          .map(([ dimension, dimeWithSplit ]) => {
            const uniqueLabels = uniqueBy(
              dimeWithSplit,
              (a, b) => a.dimensionItem === b.dimensionItem && a.percentage === b.percentage
            );

            const sum = sumLabels(
              uniqueLabels
                .map(item => ({ dimensionItemId: item.dimensionItem, percentage: item.percentage }))
            );

            if (sum === 100) {
              return {
                dimension: Number(dimension),
                labels: uniqueLabels
                  .map((item) => ({
                    dimensionItemId: item.dimensionItem,
                    percentage: Number(item.percentage),
                    nominalValue: (+(item.percentage) / 100) * nominalValue,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    draft: (item as any).draft
                  })),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                draft: uniqueLabels.every(item => (item as any).draft)
              };
            } else {
              return getMultipleDimensionLabel(dimension);
            }
          });

        return { dimensions };

      }
    }

    return { dimensions: [ emptyDimension ] };
  };
};

export default useSplitItemDefault;
