import React, { forwardRef, useCallback, useMemo, useState, } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import SearchableSelect from '../../../searchableSelect/SearchableSelect';
import styles from './Editors.module.scss';
import { Account } from '../../../../../types/statutory.types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Button from '../../../button/Button';
import useKeyPressListener from '../../../../../hooks/useKeyPressListener';
import { getDisplayName } from '../../../../../utils/common.utils';

const APPLY_KEY_BINDING = { code: 'Enter' };

type SelectValue = Value | number | string;

type Value = {
  id: number | string;
  name: number | string;
};

interface ValuesProps {
  values: SelectValue[];
}

const AccountCellEditor = forwardRef((
  props: ICellEditorParams & ValuesProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  const value = props.value;
  const column = props.column.getColId();

  const [ primaryOpen, setPrimaryOpen ] = useState(false);
  const [ primaryValue, setPrimaryValue ] = useState<number>(value?.primary);
  const [ counterOpen, setCounterOpen ] = useState(false);
  const [ counterValue, setCounterValue ] = useState<number>(value?.counter);
  const [ t ] = useTranslation('common');

  const options = useMemo(() => {
    return props.values.map((_value: Account) => ({
      value: _value.id,
      label: props.formatValue ? props.formatValue(_value) : typeof _value === 'object' ?
        getDisplayName(_value?.name) : _value,
    }));
  }, [ props.values, props.formatValue ]);

  const setFocusOnCell = useCallback(() => {
    const focusedCell = props.api.getFocusedCell();
    props.api.clearFocusedCell();
    props.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column, focusedCell.rowPinned);
  }, []);

  const onChange = useCallback((key: number, type: 'primary' | 'counter') => {
    if (type === 'primary') {
      setPrimaryValue(key);
    }
    if (type === 'counter') {
      setCounterValue(key);
    }
  }, [ props.values ]);

  const applyOnEnter = useCallback(() => {
    if (!primaryOpen && !counterOpen) {
      props.node.setDataValue(column, { primary: primaryValue, counter: counterValue });
      setFocusOnCell();
    }
  }, [ primaryOpen, counterOpen, primaryValue, counterValue ]);

  useKeyPressListener({ keyBinding: APPLY_KEY_BINDING, cb: applyOnEnter });

  const onOpenChange = useCallback((_open: boolean, type: 'primary' | 'counter') => {
    if (type === 'primary') {
      setPrimaryOpen(_open);
    }
    if (type === 'counter') {
      setCounterOpen(_open);
    }
  }, []);

  const onApply = () => {
    props.node.setDataValue(column, { primary: primaryValue, counter: counterValue });
    setFocusOnCell();
  };

  const onCancel = () => {
    setFocusOnCell();
  };

  const inputs = [
    {
      label: t('account-editor.primary.label'),
      open: primaryOpen,
      onOpenChange: (open) => onOpenChange(open, 'primary'),
      value: primaryValue,
      onChange: (key) => onChange(key, 'primary')
    },
    {
      label: t('account-editor.counter.label'),
      open: counterOpen,
      onOpenChange: (open) => onOpenChange(open, 'counter'),
      value: counterValue,
      onChange: (key) => onChange(key, 'counter')
    }
  ];

  return (
    <div className={ styles.accountEditor }>
      <Card
        title={ t('account-editor.title') }
        extra={
          <CloseIcon
            className={ styles.closeIcon }
            onClick={ () => setFocusOnCell() }/> }>
        {
          inputs.map(
            (input) => (
              <div className={ styles.inputGroup } key={ input.label }>
                <label>{ input.label }</label>  
                <SearchableSelect
                  open={ input.open }
                  onDropdownVisibleChange={ input.onOpenChange }
                  dropdownMatchSelectWidth={ 350 }
                  value={ input.value }
                  options={ options }
                  popupClassName='ag-custom-component-popup'
                  className={ styles.fullCellInput }
                  onChange={ input.onChange }
                  callback={ (e) => {
                    if (e.key === 'Tab') {
                      e.preventDefault();
                      props.api.tabToNextCell();
                    }
                  } }
                />
              </div>
            )
          )
        }
      </Card>
      <div className={ styles.footer }>
        <Button type='text' onClick={ onCancel }>{ t('account-editor.cancel') }</Button>
        <Button autoFocus onClick={ onApply }>{ t('account-editor.apply') }</Button>
      </div>
    </div>
  );
});

AccountCellEditor.displayName = 'AccountCellEditor';
export default AccountCellEditor;
